.for-brokers-where-we-win {
  width: 80%;
  margin: 0 auto;
}

.for-brokers-section-header {
  text-align: center;
  font-size: 30px;
}

.for-brokers-section-p {
  font-size: 24px;
}

.where-we-win-table {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    border-collapse: collapse;
    table-layout: fixed; /* Ensures consistent column widths */
  }
  
  .where-we-win-table th,
  .where-we-win-table td {
    border: 1px solid black;
    padding: 8px;
  }
  
  .where-we-win-table th {
    background-color: #f2f2f2;
    text-align: center; /* Center text in headers */
  }
  
  .check-td {
    text-align: center; /* Center content in the cell */
    vertical-align: middle; /* Center content vertically */
  }
  
  .where-we-win-check {
    width: 20px; /* Increase the size for better visibility */
    height: 20px; /* Ensure the check mark is sized appropriately */
  }

  .for-brokers-sub-section {
    width: 80%;
    margin: 0 auto;
  }

.for-brokers-section-body {
  font-size: 24px;
}

@media screen and (max-width: 600px) {
  .for-brokers-where-we-win {
    width: 90%;
  }

  .where-we-win-column-head {
    font-size: 14px;
  }

  .where-we-win-row {
    font-size: 14px;
  }

  .for-brokers-sub-section {
    width: 90%;
  }

  .for-brokers-section-header {
    font-size: 24px;
  }

  .for-brokers-section-p {
    font-size: 18px;
  }

  .for-brokers-section-body {
    font-size: 18px;
  }

}