/* Basic reset */
.dashboard-home-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: #f7f9fc;
    padding: 20px;
  }

  
  .dashboard-home-form {
    background: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
  }
  
  .dashboard-home-form input,
  .dashboard-home-form button {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
  }
  
  .dashboard-home-form input:focus {
    border-color: #007bff;
    outline: none;
  }

  .dashboard-buttons {
    display: flex;
  }
  
  .lender-dashboard-blue-button {
    background-color: #007bff;
    margin-right: 10px;
    height: 50px;
    width: 120px;
    font-size: 16px;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .lender-dashboard-blue-button:hover {
    background-color: #0056b3;
  }
  
  .dashboard-home-container h1 {
    margin-bottom: 20px;
    text-align: center;

  }
  
  .dashboard-error-message {
    color: red;
    text-align: center;
    margin-top: 10px;
  }
  
  .dashboard-success-message {
    color: green;
    text-align: center;
    margin-top: 10px;
  }
  
  .password-requirements {
    font-size: 14px;
    color: #333;
    margin-bottom: 10px;
    list-style-type: none;
  }
  
  .password-requirements ul {
    list-style-type: none;
    padding-left: 0;
  }
  
  .password-requirements li {
    padding: 5px 0;
    
  }
  
  .password-requirements li.valid {
    color: green;
    font-weight: bold;
  }
  

  @media screen and (max-width: 550px) {
    .dashboard-buttons {
      width: 90%;
      flex-direction: row-reverse;
      position: relative;
      left: 2%;
    }
  }
