/* Main Container */


.broker-dash-lender-referrals {
    padding: 20px;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    background-color: #f5f5f5;
    border: 2px solid darkgrey;
    border-radius: 8px;
  }
  
  /* Title */
  .broker-dash-title {
    font-size: 28px;
    margin-bottom: 20px;
    color: #333;
  }
  
  /* Collapse Controls */
  .collapse-controls {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .collapse-all-button,
  .uncollapse-all-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .collapse-all-button:hover,
  .uncollapse-all-button:hover {
    background-color: #0056b3;
  }
  
  /* Filter Input */
  .filter-container {
    margin-bottom: 20px;
    width: 50%;
  }
  
  .filter-input {
    width: 100%;
    max-width: 400px; /* Limit the width */
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  /* Error Message */
  .error-message {
    color: red;
    margin-bottom: 20px;
  }
  
  /* Leads List */
  .leads-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  /* Lead Item */
  .lead-item {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 6px;
    padding: 10px 20px;
    margin-bottom: 20px;
  }
  
  /* Quote Header */
  .quote-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .toggle-button {
    background-color: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
    margin-right: 20px;
  }
  
  .toggle-button:focus {
    outline: none;
  }
  
  /* Collapsed Info */
  .collapsed-info p {
    margin: 0;
    font-size: 16px;
  }
  
  /* Quote Details */
  .quote-details {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    margin-bottom: 20px;
  }
  
  /* Sections and Paragraphs within Quote Details */
  .quote-details > p,
  .quote-details > .quote-section {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 6px;
  }
  
  /* Section Titles */
  .section-title {
    font-size: 18px;
    margin-bottom: 10px;
    color: #007bff;
  }
  
  /* Attached Files */
  .attached-files-list {
    list-style-type: none;
    padding: 0;
  }
  
  .attached-file-link {
    color: #007bff;
    text-decoration: none;
  }
  
  .attached-file-link:hover {
    text-decoration: underline;
  }
  
  /* Lead Actions */
  .lead-actions {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: flex-end;
    margin-top: 20px;
  }
  
  .action-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .action-button:hover {
    background-color: #0056b3;
  }
  
  .action-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  /* No Leads Message */
  .no-leads-message {
    font-size: 16px;
    color: #666;
  }

  /* General Modal Styles */
.broker-dash-lender-archive-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  width: 90%;
  max-width: 500px;
  padding: 20px;
  z-index: 1000;
  font-family: Arial, sans-serif;
}

/* Modal Header */
.broker-dash-lender-archive-modal-header {
  font-size: 1.4rem;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

/* Label Styles */
.broker-dash-lender-archive-modal label {
  display: block;
  font-size: 0.9rem;
  color: #555;
  font-weight: 500;
  margin-bottom: 10px;
}

/* Archive Reason Label */
.broker-dash-lender-archive-modal-reason {
  margin-bottom: 16px;
}

/* Dropdown Styles */
.broker-dash-lender-archive-modal select {
  width: 100%;
  padding: 10px;
  font-size: 0.9rem;
  color: #333;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f9f9f9;
  margin-top: 6px;
  margin-bottom: 16px;
}

.broker-dash-lender-archive-modal select:focus {
  outline: none;
  border-color: #007bff;
  background-color: #fff;
}

/* Input Field for 'Other Carrier' */
.broker-dash-lender-archive-modal input[type="text"] {
  width: 100%;
  padding: 10px;
  font-size: 0.9rem;
  color: #333;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f9f9f9;
  margin-bottom: 16px;
}

.broker-dash-lender-archive-modal input[type="text"]:focus {
  outline: none;
  border-color: #007bff;
  background-color: #fff;
}

/* Button Styles */
.broker-dash-lender-archive-modal button {
  width: 48%;
  padding: 12px;
  font-size: 0.95rem;
  font-weight: 600;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 12px;
  display: inline-block;
  margin-right: 2%;
}

.broker-dash-lender-archive-modal button:last-of-type {
  margin-right: 0;
}

.broker-dash-lender-archive-modal button:hover {
  opacity: 0.9;
}

.broker-dash-lender-archive-modal button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Submit and Cancel Button Colors */
.broker-dash-lender-archive-modal button:first-of-type {
  background-color: #28a745; /* Green for Submit */
}

.broker-dash-lender-archive-modal button:last-of-type {
  background-color: #dc3545; /* Red for Cancel */
}

  
  /* Responsive Design */
  @media (max-width: 768px) {
    .quote-details {
      grid-template-columns: 1fr;
    }
  
    .lead-actions {
      justify-content: center;
    }
  }
  
  /* Responsive Styles */
@media (max-width: 600px) {
  .broker-dash-lender-archive-modal {
    width: 90%;
    padding: 16px;
  }
  .broker-dash-lender-archive-modal-header {
    font-size: 1.2rem;
  }
  .broker-dash-lender-archive-modal button {
    width: 100%;
    font-size: 0.85rem;
    padding: 10px;
    margin-bottom: 8px;
  }
}