

.blog-videopost-video {
    /*this is from the videopost1(etc) pages */
    
    display: flex;
    justify-content: center;

}



