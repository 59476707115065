

.homepage-bottom {
    width: 80%;
    margin: 0 auto;
    display: grid;
    grid-template: 50% 50% / 100%;
}

.bottom-disclaimer {
    width: 90%;
    position: relative;
    margin: 0 auto;
    border-bottom: 1px solid lightgrey;
    grid-area: 1 / 1 / 1 / 1;
    align-self: center;
    padding-bottom: 30px;
    font-size: 10px;
}

.bottom-secure {
    width: 90%;
    position: relative;
    margin: 0 auto;
}

.bottom-secure img {
    display: block;
    position: relative;
    margin: 0 auto;
    width: 13vw;
}

.bottom-allrights {
    display: block;
    position: relative;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 90px;
}


@media screen and (min-width: 300px) and (max-width: 687px) {
    
    .homepage-bottom {
        grid-template: 70% 30% / 100%;
    }
    
    .bottom-secure img {
        width: 25vw;

    }
}

