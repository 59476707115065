* {
    font-family: 'Albert Sans', sans-serif;
}

.how-it-works-tiles {
    display: grid;
    grid-template: 1fr 1fr / 1fr 1fr;
    width: 90%;
    margin: 0 auto;
    padding-top: 5vw;
    padding-bottom: 4vw;
    grid-gap: 2vw;
    
}

.how-it-works-step {
    display: grid;
    grid-template: 25% 75% / 40% 60%;
    border: 2px solid lightgrey;
    border-radius: 4px;
    padding-bottom: 3vw;
}

.how-it-works-stepheader {
    display: grid;
    grid-area: 1 / 1 / 1 / 1;
    justify-self: center;
    font-weight: bold;
    font-size: 1.5vw;;
}

.step-img {
    display: grid;
    grid-area: 2 / 1 / 2 / 1;
    width: 12vw;
    justify-self: center;

}

.how-it-works-steptitle {
    display: grid;
    grid-area: 1 / 2 / 1 / 2;
    font-weight: bold;
    position: relative;
    top: 2.5vw;
    font-size: 1.5vw;
    
}

.how-it-works-stepbody {
    position: relative;
    top: 1.5vw;
    font-size: 1.5vw;
    padding-right: .5vw;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .how-it-works-tiles {
        grid-template: 1fr 1fr 1fr 1fr / 1fr;
        width: 80%;
    }

    .how-it-works-stepheader {
        font-size: 2.5vw;
    }

    .step-img {
        width: 16vw;
    }

    .how-it-works-steptitle {
        font-size: 3vw;
        top: 3vw;
    }

    .how-it-works-stepbody {
        font-size: 2.5vw;
        
    }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
    .how-it-works-tiles {
        grid-template: 1fr 1fr 1fr 1fr / 1fr;
        width: 80%;
    }

    .how-it-works-stepheader {
        font-size: 2.5vw;
    }

    .step-img {
        width: 16vw;
    }

    .how-it-works-steptitle {
        font-size: 3vw;
        top: 3vw;
    }

    .how-it-works-stepbody {
        font-size: 2.5vw;
        
    }
}

@media screen and (min-width: 477px) and (max-width: 575px) {
    .how-it-works-tiles {
        grid-template: 1fr 1fr 1fr 1fr / 1fr;
        width: 80%;
    }

    .how-it-works-stepheader {
        font-size: 2.5vw;
    }

    .step-img {
        width: 16vw;
    }

    .how-it-works-steptitle {
        font-size: 3vw;
        top: 3vw;
    }

    .how-it-works-stepbody {
        font-size: 2.5vw;
        
    }
}

@media screen and (min-width: 300px) and (max-width: 476px) {
    .how-it-works-tiles {
        grid-template: 1fr 1fr 1fr 1fr / 1fr;
        width: 80%;
        
    }

    .how-it-works-step {
        display: grid;
        grid-template: 10% 1fr 15% 30% / 1fr;
        justify-items: center;
    }

    .how-it-works-stepheader {
        grid-area: 1 / 1 / 1 / 1;
        font-size: 5vw;
    }

    .step-img {
        grid-area: 2 / 1 / 2 / 1;
        width: 80%;
    }

    .how-it-works-steptitle {
        grid-area: 3 / 1 / 3 / 1;
        font-size: 5vw;
        top: 3vw;
    }

    .how-it-works-stepbody {
        grid-area: 4 / 1 / 4 / 1;
        font-size: 5vw;
        text-align: center;
        

        
    }


}