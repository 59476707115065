

.post-page {
    display: flex;
    width: 60%;
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;
    border-bottom: 1px solid lightgrey;
    padding-bottom: 2vw;
    margin-bottom: 5vw;
}

.post-page h1 {
    width: 100%;
    text-align: center;
}

.post-page h2 {
    text-align: center;
}

.post-page img {
    display: block;
    margin: 0 auto;
    position: relative;
    max-width: 40%;
}


#return-to-blogs-button {

    position: relative ;
    top: 8vw;
    left: 7vw;
    background-color: #F4524D;
    border: none;
    color: white;
    height: 3vw;
    width: 9vw;
    cursor: pointer;
}



@media screen and (min-width: 992px) and (max-width: 1199px) {
    #return-to-blogs-button {
        height: 5vw;
        
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    #return-to-blogs-button {
        height: 5vw;
        font-size: 1.3vw;
        
    }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
    #return-to-blogs-button {
        height: 5vw;
        width: 12vw;
        font-size: 1.8vw;
        top: 2vw;
        
    }

    .post-page {
        width: 80%;
    }



}

@media screen and (min-width: 477px) and (max-width: 575px) {
    #return-to-blogs-button {
        height: 7vw;
        width: 14vw;
        font-size: 2.2vw;
        top: 2vw;
        
    }

    .post-page {
        width: 80%;
    }

}

@media screen and (min-width: 300px) and (max-width: 476px) {
    #return-to-blogs-button {
        height: 7vw;
        width: 14vw;
        font-size: 2.2vw;
        top: 2vw;
        
    }

    .post-page {
        width: 80%;
    }

    .post-page img {
        max-width: 60%;
    }

}