

.stickybanner {
    display: flex;
    background-color: #7b0072;
    height: 4vw;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 999;

}

.btn-white {
    background-color: white;
    border: none;
    color: #7b0072;
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    font-weight: bold;
    border-radius: 40px;
    transition: 0.15s;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 14px;
    padding-right: 14px;
    cursor: pointer;

}

.btn-white:hover {
    background-color: rgb(248, 239, 248);
}

@media screen and (min-width: 639px) and (max-width: 767px) {
    .stickybanner {
        height: 7vw;
    }
}

@media screen and (min-width: 300px) and (max-width: 638px) {
    .stickybanner {
        height: 9vw;
    }
}