/* Modal Overlay */
.ReactModal__Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75) !important;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
/* Modal Content */
.ReactModal__Content {
    position: relative;
    width: 90%;
    max-width: 600px;
    background-color: #ffffff;
    padding: 20px 30px; /* Ensure there's padding to prevent content from touching edges */
    border-radius: 8px;
    outline: none;
    max-height: 90vh;
    overflow-y: auto;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }
  
  /* Modal Header */
  .modal-header {
    margin-bottom: 20px;
    padding-bottom: 50px;
    text-align: center;
    font-size: 24px;
    color: #333;
  }
  
  /* File Upload Container */
  .file-upload-container {
    border: 2px dashed #ccc;
    padding: 20px;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px; /* Add margin-top to create space below the header */
    transition: border-color 0.3s ease;
  }
  
  .file-upload-container.drag-over {
    border-color: #007BFF;
  }
  
  
  .upload-instruction {
    margin-bottom: 15px;
    color: #555;
    font-size: 16px;
  }

  /* Hide the native file input element */
.agent-upload-file-input {
    display: none;
  }
  
  /* Button Group */
  .button-group {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .modal-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 6px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .choose-button {
    background-color: #007BFF;
    color: #fff;
  }
  
  .choose-button:hover {
    background-color: #0056b3;
  }
  
  .upload-button {
    background-color: #28a745;
    color: #fff;
  }
  
  .upload-button:hover {
    background-color: #218838;
  }
  
  .upload-button:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
  }
  
  .remove-button {
    background-color: #dc3545;
    color: #fff;
    margin-top: 10px;
  }
  
  .remove-button:hover {
    background-color: #c82333;
  }
  
  .close-button {
    background-color: #6c757d;
    color: #fff;
    margin-top: 20px;
  }
  
  .close-button:hover {
    background-color: #5a6268;
  }
  
  /* File List */
  .file-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .file-item {
    margin-bottom: 15px;
    text-align: left;
    border-bottom: 1px solid #e9ecef;
    padding-bottom: 10px;
  }
  
  .file-link {
    font-size: 16px;
    color: #007BFF;
    text-decoration: none;
  }
  
  .file-link:hover {
    text-decoration: underline;
  }
  
  .file-status {
    margin-top: 5px;
    font-size: 14px;
    color: #555;
  }
  
  .status-uploaded {
    color: green;
  }
  
  .status-ready {
    font-weight: bold;
  }
  
  .status-complete {
    color: green;
  }
  
  /* Success and Error Messages */
  .success-message {
    color: green;
    font-weight: bold;
    margin-top: 10px;
  }
  
  .error-message {
    color: red;
    font-weight: bold;
    margin-top: 10px;
  }
  
  /* Loader */
  .loader {
    margin-top: 10px;
    font-size: 16px;
    color: #007BFF;
  }
  
  /* Responsive Design */
  @media (max-width: 600px) {
    .ReactModal__Content {
      padding: 15px 20px;
    }
  
    .button-group {
      flex-direction: column;
    }
  
    .modal-button {
      width: 100%;
      margin-bottom: 10px;
    }
  
    .file-item {
      text-align: left;
    }
  }
  