.broker-dashboard-home-container {
  width: 90%;
  margin: 0 auto;
}

.broker-dashboard-home-container h1,
.broker-dashboard-home-container h2 {
  text-align: center;
}

.broker-lender-quote-instructions h3 {
  text-align: center;
}

.broker-lender-quote-instructions ol {
  width: 70%;
  margin: 0 auto;
  font-size: 18px;
}

.dashboard-blue-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.dashboard-blue-button:hover {
  background-color: #0056b3;
}

/* Container for buttons */
.dashboard-buttons {
  display: flex;
  gap: 10px; /* Space between buttons */
  justify-content: center; /* Align buttons to the left */
}

.quote-item {
  list-style: none;
  margin: 0 auto;
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: grid;
  grid-template: 1fr / 1fr 1fr 1fr; /* Ensure enough space for buttons and text */
  align-items: start;
  width: 80%;
}

.quote-details {
  grid-area: 1 / 1 / 2 / 2;
  align-self: center;
}

.quote-item p {
  margin: 5px 0;
}

.quote-item strong {
  font-weight: bold;
}

/* Default grey button */
.button-grey {
  background-color: grey;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 10px;
  font-size: 16px;
}

.button-grey:hover {
  background-color: green;
}

/* Green button for clicked state */
.button-green {
  background-color: green;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 10px;
  font-size: 16px;
}

.button-green:hover {
  background-color: green;
}

.broker-quote-status {
  grid-area: 1 / 2 / 2 / 3;
  align-self: center;
  width: 80%;
  padding-left: 20px;
}

.broker-payment-status {
  grid-area: 1 / 3 / 2 / 4;
  align-self: center;
  justify-self: center;
  width: 80%;
}

@media screen and (min-width: 500px) and (max-width: 1199px) {
  .broker-dashboard-home-container {
    width: 99%;
  }

  .dashboard-buttons {
    position: relative;
    right: 1%;
  }

  .broker-quote-status {
    width: 90%;
  }

  .broker-quote-status p,
  .broker-payment-status p {
    font-size: 12px;
  }

  .broker-payment-status {
    width: 90%;
    padding-left: 25%;
  } 

  .broker-quote-status button, 
  .broker-payment-status button {
    font-size: 14px;
  }

  .quote-item {
    width: 90%;
  }



}

@media screen and (min-width: 300px) and (max-width: 499px) {
  .broker-dashboard-home-container {
    width: 90%;
  }

  .dashboard-buttons {
    position: relative;
    right: 3%;
  }

  .broker-lender-quote-instructions, 
  .broker-lender-quote-instructions li {
    font-size: 14px;
  }

  .quote-item {
    width: 96%;
    position: relative;
    right: 7%;
    grid-template: 1fr 1fr 1fr / 1fr;
    height: auto;
    justify-items: center;
  }

  .quote-details {
    grid-area: 1 / 1 / 2 / 2;
  }

  .broker-quote-status {
    grid-area: 2 / 1 / 3 / 2;
    padding: 0px;
  }

  .broker-payment-status {
    grid-area: 3 / 1 / 4 / 2;
  }

  
}