
.for-brokers-top {
    width: 100%;
    margin: 0 auto;
    display: grid;
    grid-template: 1fr 1fr / 1fr 1fr 1fr;
}

.for-brokers-top-img {
    width: 100%;
    grid-area: 1 / 1 / 3 / 4;
    
}