
/*start of top section */

.rightcoverage-top {
    display: flex;
    width: 80%;
    margin: 0 auto;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
}

.rightcoverage-rcbp {
    font-size: 34px;
}

.rightcoveragetop-logo {
    position: relative;
    bottom: 28px;
}

.rightcoverage-A {
    color: #7b0372;
    font-size: 54px;
    font-weight: 800;
}

.rightcoverage-B {
    color: #efbb37;
    font-size: 54px;
    font-weight: 800;
}

.rightcoverage-C {
    color: #6c9311;
    font-size: 54px;
    font-weight: 800;
}

.rightcoverage-quote {
    font-size: 44px;
}

.rightcoverage-top p {
    position: relative;
    bottom: 38px;
    font-size: 20px;
}

/*start of tile section*/

.rightcoverage-tiles {
    display: grid;
    position: relative;
    grid-template: 1fr 1fr 1fr 1fr / 1fr;
    width: 80%; 
    margin: 0 auto;
    grid-gap: 20px;
    padding-bottom: 40px;
    
}

.rightcoverage-bestprice,
.rightcoverage-rightcoverage,
.rightcoverage-technology,
.rightcoverage-professionalservice {
    display: grid;
    grid-template: 1fr / 25% 75%;
    width: 85%;
    margin: 0 auto;
    border: 1px solid #ececec;
    border-radius: 8px;
    box-shadow: 0px 1.5px 1.5px 0px rgb(0 0 0 / 20%);
    padding: 10px 20px 10px 10px;    
    align-items: center;
    font-family: 'Montserrat', sans-serif;

}

.rightcoverage-bestprice img,
.rightcoverage-rightcoverage img,
.rightcoverage-technology img,
.rightcoverage-professionalservice img {
    display: grid;
    width: 190px;
    grid-area: 1 / 1 / 1 / 1;
    
    
}

.rightcoveragetiles-p1 {
    display: flex;
    flex-direction: column;
    font-weight: bold;
}

.rightcoveragetiles-p2 {
    line-height: 1.5;
}

@media screen and (min-width: 992px) and (max-width: 1199px) {

    .rightcoverage-bestprice img,
    .rightcoverage-rightcoverage img,
    .rightcoverage-technology img,
    .rightcoverage-professionalservice img {
        width: 15vw;
    }   
}

@media screen and (min-width: 768px) and (max-width: 991px) {

    .rightcoverage-bestprice img,
    .rightcoverage-rightcoverage img,
    .rightcoverage-technology img,
    .rightcoverage-professionalservice img {
        width: 15vw;
    }

}

@media screen and (min-width: 576px) and (max-width: 767px) {
    .rightcoverage-bestprice,
    .rightcoverage-rightcoverage,
    .rightcoverage-professionalservice {
        display: grid;
        grid-template: 25% 60% / 1fr;
        justify-items: center;
    }

    .rightcoverage-tiles {
        width: 90%;
    }

    .rightcoverage-technology {
        grid-template: 20% 80% / 1fr;
        justify-items: center;
        padding-top: 8vw;
    }



    .rightcoverage-bestprice img,
    .rightcoverage-rightcoverage img,
    .rightcoverage-technology img,
    .rightcoverage-professionalservice img {
        grid-area: 1 / 1 / 1 / 1;
        width: 20vw;
    }

    .rightcoverage-bestprice img,
    .rightcoverage-rightcoverage img,
    .rightcoverage-professionalservice img {
        position: relative;
        top: 5vw;
    }

    .rightcoveragetiles-p1p2 {
        grid-area: 2 / 1 / 2 / 1;
    }

    .rightcoveragetiles-p1 {
        text-align: center;
    }

}

@media screen and (min-width: 477px) and (max-width: 575px) {
    .rightcoverage-bestprice,
    .rightcoverage-rightcoverage,
    .rightcoverage-professionalservice {
        display: grid;
        grid-template: 25% 60% / 1fr;
        justify-items: center;
    }

    .rightcoverage-tiles {
        width: 96%;
    }

    .rightcoverage-technology {
        grid-template: 20% 80% / 1fr;
        justify-items: center;
    }



    .rightcoverage-bestprice img,
    .rightcoverage-rightcoverage img,
    .rightcoverage-technology img,
    .rightcoverage-professionalservice img {
        grid-area: 1 / 1 / 1 / 1;
        width: 20vw;
    }

    .rightcoverage-bestprice img,
    .rightcoverage-rightcoverage img,
    .rightcoverage-professionalservice img {
        position: relative;
        top: 5vw;
    }

    .rightcoveragetiles-p1p2 {
        grid-area: 2 / 1 / 2 / 1;
    }

    .rightcoveragetiles-p1 {
        text-align: center;
    }
}

@media screen and (min-width: 300px) and (max-width: 476px) {
    .rightcoverage-bestprice,
    .rightcoverage-rightcoverage,
    .rightcoverage-professionalservice {
        display: grid;
        grid-template: 25% 60% / 1fr;
        justify-items: center;
    }

    .rightcoverage-tiles {
        width: 100%;
    }

    .rightcoverage-technology {
        grid-template: 20% 80% / 1fr;
        justify-items: center;
        
    }



    .rightcoverage-bestprice img,
    .rightcoverage-rightcoverage img,
    .rightcoverage-technology img,
    .rightcoverage-professionalservice img {
        grid-area: 1 / 1 / 1 / 1;
        width: 30vw;
    }

    .rightcoverage-bestprice img,
    .rightcoverage-rightcoverage img,
    .rightcoverage-professionalservice img {
        position: relative;
        top: 5vw;
    }

    .rightcoveragetiles-p1p2 {
        grid-area: 2 / 1 / 2 / 1;
    }

    .rightcoveragetiles-p1 {
        text-align: center;
    }

    .rightcoverage-rcbp {
        font-size: 30px;
    }

    .rightcoverage-A,
    .rightcoverage-B,
    .rightcoverage-C {
        font-size: 30px;
    }

    .rightcoverage-quote {
        font-size: 30px;
    }
}