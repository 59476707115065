* {
    font-family: 'Albert Sans', sans-serif;
}

.bundlequoteinfo-Birthdate {
    display: grid;
    grid-template: 1fr 1fr 1fr / 1fr 1fr 1fr;
    border: 1px solid rgb(165, 165, 165);
    box-shadow: 0px 1px 1px 0px;
    border-radius: 4px;
    width: 60%;
    margin: 0 auto;
    justify-content: center;
    background-color: white;
    height: 28vw;
    margin-top: 2.5vw;
    padding-bottom: 2.8vw;

}



.bundlequoteinfo-birthdate-abc {
    display: flex;
    flex-direction: row;
    grid-area: 1 / 1 / 2 / 4;
    justify-content: center;
    
}



#bundle-birthdate-abc-a, 
#bundle-birthdate-abc-b,
#bundle-birthdate-abc-c {
    display: flex;
    margin: 0 auto;
    border-radius: 50%;
    height: 6vw;
    width: 6vw;
    justify-content: center;
    align-items: center;
    font-size: 1.8vw;
      
}

#bundle-birthdate-abc-b {
    background-color: #6a9a07;
    font-weight: bold;
    color: white;
}

#bundle-birthdate-abc-birthdate {
    font-weight: bold;
    font-size: 1.8vw;
}

#bundle-birthdate-abc-address,
#bundle-birthdate-abc-contact {
    font-size: 1.8vw;
}

#bundle-birthdate-abc-a,
#bundle-birthdate-abc-c {
    background-color: #b1d858;
    color: white;

}


@media screen and (min-width: 768px) and (max-width: 991px) {
    .bundlequoteinfo-Birthdate {
        width: 80%;
        height: 34vw;
    }
}



@media screen and (min-width: 576px) and (max-width: 767px) {
    .bundlequoteinfo-Birthdate {
        width: 80%;
        grid-template: 1fr 1fr 1fr 1fr 1fr / 1fr;
        height: 58vw;
    }

    .bundlequoteinfo-birthdate-abc {
        grid-area: 1 / 1 / 2 / 2;
    }

    #bundle-birthdate-abc-birthdate {
        font-size: 2.8vw;
    }
    
    #bundle-birthdate-abc-address,
    #bundle-birthdate-abc-contact {
        font-size: 2.8vw;
    }

    #bundle-birthdate-abc-a, 
    #bundle-birthdate-abc-b,
    #bundle-birthdate-abc-c {
        width: 10vw;
        height: 10vw;
        font-size: 5vw;
}

}

@media screen and (min-width: 477px) and (max-width: 575px) {
    .bundlequoteinfo-Birthdate {
        width: 80%;
        grid-template: 1fr 1fr 1fr 1fr 1fr / 1fr;
        height: 64vw;
    }

    .bundlequoteinfo-birthdate-abc {
        grid-area: 1 / 1 / 2 / 2;
    }

    #bundle-birthdate-abc-birthdate {
        font-size: 2.8vw;
    }
    
    #bundle-birthdate-abc-address,
    #bundle-birthdate-abc-contact {
        font-size: 2.8vw;
    }

    #bundle-birthdate-abc-a, 
    #bundle-birthdate-abc-b,
    #bundle-birthdate-abc-c {
        width: 10vw;
        height: 10vw;
        font-size: 5vw;
}

}

@media screen and (min-width: 300px) and (max-width: 476px) {
    .bundlequoteinfo-Birthdate {
        width: 80%;
        grid-template: 1fr 1fr 1fr 1fr 10% / 1fr;
        height: auto;
    }

    .bundlequoteinfo-birthdate-abc {
        grid-area: 1 / 1 / 2 / 2;
    }

    #bundle-birthdate-abc-address {
        font-size: 2.8vw;
    }
    
    #bundle-birthdate-abc-birthdate,
    #bundle-birthdate-abc-contact {
        font-size: 2.8vw;
    }

    #bundle-birthdate-abc-a, 
    #bundle-birthdate-abc-b,
    #bundle-birthdate-abc-c {
        width: 10vw;
        height: 10vw;
        font-size: 5vw;
}

}



