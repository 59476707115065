/* Container Styling */
.broker-my-account-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  background: #f5f7fa;
}

/* Header Section */
.broker-my-account-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  position: relative;
}

.broker-my-account-header h1 {
  font-size: 2.5em;
  color: #2c3e50;
  margin: 0;
}

/* Back to Dashboard Button */
.broker-account-dashboard-button {
  background: #3498db;
  color: #ffffff;
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  font-size: 1em;
  cursor: pointer;
  transition: background 0.3s ease;
  position: absolute;
  left: 0;
}

.broker-account-dashboard-button:hover {
  background: #2980b9;
}

/* Agent Information Styling */
.broker-my-account-info {
  background: #ffffff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
}

.broker-my-account-info p {
  margin: 10px 0;
  font-size: 1.1em;
  color: #34495e;
}

.broker-my-account-info p strong {
  color: #2c3e50;
}

.broker-my-account-info .full-width {
  width: 100%;
}

.broker-my-account-info .address-section {
  margin-top: 20px;
}

.broker-my-account-info .address-section p {
  margin: 5px 0;
}

/* Sections (Payments and Income) Styles */
.section {
  background: #ffffff;
  padding: 30px;
  border-radius: 8px;
  margin-bottom: 40px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.section h2 {
  font-size: 1.8em;
  margin-bottom: 20px;
  color: #2c3e50;
}

.section p {
  font-size: 1.1em;
  color: #34495e;
}

/* Buttons for Expanding Sections */
.section button {
  margin-top: 15px;
  padding: 10px 20px;
  background: #3498db;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  font-size: 1em;
  cursor: pointer;
  transition: background 0.3s ease;
}

/* Adjusted Toggle Button */
.month-toggle-button {
  width: auto;
  max-width: 300px; /* Set a flexible max width to fit on one line */
  padding: 8px 15px;
  background: #e67e22;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9em;
  transition: background 0.3s ease;
  text-align: left; /* Align text to the left */
  margin-top: 10px;
}

.month-toggle-button:hover {
  background: #d35400;
}

/* Itemized Payment and Income List Styles */
.itemized-payments,
.itemized-income {
  margin-top: 20px;
}

.itemized-payments ul,
.itemized-income ul {
  list-style-type: none; /* Remove bullet points */
  padding: 0;
  margin: 0;
}

.itemized-payment-item {
  background: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.itemized-payment-item p {
  margin: 5px 0;
  font-size: 0.95em;
  color: #555;
}

.itemized-payment-item p strong {
  color: #2c3e50;
  font-weight: bold;
}

/* Responsive Design Adjustments */
@media (max-width: 768px) {
  .broker-my-account-container {
    padding: 20px 10px;
  }

  .broker-my-account-info,
  .section {
    padding: 20px;
  }

  .broker-my-account-header {
    flex-direction: column;
    align-items: center;
  }

  .broker-my-account-header h1 {
    font-size: 2em;
    margin-bottom: 15px;
  }

  .broker-account-dashboard-button {
    font-size: 0.9em;
    padding: 8px 16px;
    position: static;
    margin-bottom: 20px;
  }

  .section h2 {
    font-size: 1.5em;
  }

  .section button,
  .month-toggle-button {
    font-size: 0.9em;
    padding: 8px 16px;
  }
}

@media (max-width: 480px) {
  .broker-my-account-header h1 {
    font-size: 1.8em;
  }

  .broker-account-dashboard-button {
    font-size: 0.9em;
    padding: 10px;
  }

  .broker-my-account-info p,
  .section p {
    font-size: 1em;
  }

  .itemized-payment-item {
    padding: 10px;
  }
}



