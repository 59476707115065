/* Main Container */
.archived-quotes-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

/* Heading */
.archived-quotes-container h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
}

/* Back to Dashboard Button */
#broker-archived-quotes-blue-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    font-size: 1rem;
    cursor: pointer;
    margin-bottom: 20px;
}

#broker-archived-quotes-blue-button:hover {
    background-color: #0056b3;
}

/* Filter Container */
.filter-container {
    display: flex;
    gap: 10px;
    max-width: 600px;
    margin-bottom: 20px;
}

.filter-input {
    flex: 1;
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
}

/* Section Titles */
.archived-quotes-container h3 {
    font-size: 1.25rem;
    margin: 20px 0 10px;
    text-align: left;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
    .filter-container {
        flex-direction: column;
        max-width: 100%;
    }

    .filter-input {
        margin-bottom: 10px;
        width: 100%;
    }

    #broker-archived-quotes-blue-button {
        width: 100%;
        font-size: 0.9rem;
    }

    .archived-quotes-container h3 {
        font-size: 1.1rem;
    }
}

@media screen and (max-width: 480px) {
    .archived-quotes-container h2 {
        font-size: 1.25rem;
    }

    .archived-quotes-container h3 {
        font-size: 1rem;
    }

    #broker-archived-quotes-blue-button {
        padding: 10px;
    }
}
