/* Main container styling */
.lender-my-account-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
}

/* Account information styling */
.lender-my-account-info {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.lender-my-account-info p {
  margin: 10px 0;
}

.lender-my-account-info p strong {
  display: inline-block;
  width: 150px;
  color: #555;
  font-weight: bold;
}

/* Dashboard button */
.dashboard-blue-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin: 20px 0;
}

.dashboard-blue-button:hover {
  background-color: #0056b3;
}

/* Search and dropdown styling */
.lenderMyAccount-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  margin-top: 10px;
  outline: none;
}

.lenderMyAccount-search-results {
  list-style-type: none;
  margin: 5px 0 0 0;
  padding: 0;
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
  background-color: white;
  position: absolute;
  width: 100%;
  z-index: 1000;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.lenderMyAccount-search-results li {
  padding: 10px;
  cursor: pointer;
}

.lenderMyAccount-search-results li:hover,
.lenderMyAccount-selected {
  background-color: #f0f0f0;
}

.lenderMyAccount-dropdown {
  width: 100%;
  padding: 10px;
  margin-top: 8px;
  border-radius: 5px;
  font-size: 16px;
}

/* Section headers and layout */
.section {
  margin-top: 20px;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.section h2 {
  font-size: 1.6em;
  color: #007bff;
  margin-bottom: 15px;
}

/* Income and payment list styling */
.itemized-income, .income-archive ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.itemized-income ul li, .income-archive ul li {
  background-color: #f8f9fa;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.itemized-income ul li p, .income-archive ul li p {
  margin: 5px 0;
  font-size: 0.95em;
  color: #555;
}

/* Expand/collapse button styling */
.month-toggle-button {
  background-color: #007bff;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9em;
  margin-top: 10px;
  transition: background-color 0.3s;
}

.month-toggle-button:hover {
  background-color: #0056b3;
}

/* Loading and error text */
.loading-text, .error-text {
  font-size: 1.1em;
  text-align: center;
  color: #333;
}

.error-text {
  color: #d9534f;
}

  