

.autoquotepage1-section {
    background-color: #f2fcfc;
    padding-top: 2.5vw;
    padding-bottom: 2.5vw;
    

}

.autoquotepage1-header {
    display: flex;
    width: 80%;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    
}

.autoquotepage1-section img {
    width: 6vw;
    padding-right: 1vw;
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .autoquotepage1-section img {
        width: 6vw;
        height: 6vw;
        padding-right: 1vw;
    }
    
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .autoquotepage1-header h1 {
        font-size: 3vw;
        text-align: center;
        
    }
    .autoquotepage1-section img {
        padding-right: 0vw;
    }

    .quotepagebutton {
        bottom: 8vw;
        font-size: 3vw;
        padding: 6px 12px;

    }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
    .autoquotepage1-header h1 {
        font-size: 3.8vw;
        text-align: center;
        
    }
    .autoquotepage1-section img {
        padding-right: 2vw;
    }    

    .quotepagebutton {
        font-size: 3vw;
        bottom: 13vw;
    }
}

@media screen and (min-width: 477px) and (max-width: 575px) {
    .autoquotepage1-header h1 {
        font-size: 5.5vw;
        text-align: center;
        
    }
    .autoquotepage1-section img {
        width: 8vw;
        padding-right: 2vw;
    }    

    .quotepagebutton {
        font-size: 5vw;
        padding: 4px 8px;
        bottom: 13vw;
    }
}

@media screen and (min-width: 300px) and (max-width: 476px) {
    .autoquotepage1-header h1 {
        font-size: 5.5vw;
        text-align: center;
        
    }
    .autoquotepage1-section img {
        width: 8vw;
        padding-right: 2vw;
    }    

    .quotepagebutton {
        font-size: 5vw;
        padding: 4px 8px;
        bottom: 14vw;
    }
}


