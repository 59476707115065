/* brokerDashReferredQuotes.css */

/* Main Container */
.broker-dash-referred-quotes {
  padding: 30px;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  background-color: #f5f5f5;
  border: 2px solid darkgrey;
  border-radius: 8px;
}

/* Section Header */
.section-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.section-toggle-symbol {
  font-size: 24px;
  margin-right: 10px;
}

.section-toggle-button:focus {
  outline: none;
}

.referred-title {
  font-size: 28px;
  margin: 0;
  color: #333;
}

.referred-item-count {
  font-size: 16px;
  color: #666;
  margin-left: 10px;
}

/* Collapse Controls */
.referred-collapse-controls {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.referred-collapse-all-button,
.referred-uncollapse-all-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
}

.referred-collapse-all-button:hover,
.referred-uncollapse-all-button:hover {
  background-color: #0056b3;
}

/* Filter Input */
.referred-filter-container {
  margin-bottom: 20px;
  width: 50%;
}

.referred-filter-input {
  width: 100%;
  max-width: 400px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* No Quotes Message */
.referred-no-quotes-message {
  font-size: 16px;
  color: #666;
}

/* Quotes List */
.referred-quotes-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

/* Quote Item */
.referred-quote-item {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

/* Ensure child elements take full width */
.quote-header,
.referred-quote-details,
.referred-lead-actions {
  width: 100%;
}

/* Quote Header */
.referred-quote-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.referred-toggle-button {
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  margin-right: 50px;
}

.referred-toggle-button:focus {
  outline: none;
}

.referred-collapsed-info {
  flex-grow: 1;
}

.referred-collapsed-info p {
  margin: 0;
  font-size: 16px;
}

.referred-collapsed-info p + p {
  margin-top: 5px;
}

/* Quote Details */
.referred-quote-details {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  margin-bottom: 20px;
}

.referred-quote-details > p,
.referred-quote-details > .referred-quote-section {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 6px;
  margin: 0;
}

.referred-quote-details p + p {
  margin-top: 10px;
}

/* Section Titles */
.referred-section-title {
  font-size: 18px;
  margin-bottom: 10px;
  color: #007bff;
}

/* Attached Files */
.referred-attached-files-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.referred-attached-file-link {
  color: #007bff;
  text-decoration: none;
}

.referred-attached-file-link:hover {
  text-decoration: underline;
}

/* Lead Actions */
.referred-lead-actions {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-end;
  margin-top: 20px;
}

.referred-action-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
}

.referred-action-button:hover {
  background-color: #0056b3;
}

.referred-action-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* General Modal Styles */
.referred-archive-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  width: 90%;
  max-width: 500px;
  padding: 20px;
  z-index: 1000;
  font-family: Arial, sans-serif;
}


/* Center the 'Other Carrier' input container */
.referred-archive-modal .referred-archive-modal-label {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers the input horizontally */
}


/* Modal Title */
.referred-archive-modal-title {
  font-size: 1.4rem;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

/* Label Styling */
.referred-archive-modal-label {
  display: block;
  font-size: 0.9rem;
  color: #555;
  font-weight: 500;
  margin-bottom: 8px;
  margin-top: 16px; /* Additional spacing to ensure separation */
}

/* Select Dropdown Styles */
.referred-archive-modal-select {
  width: 100%;
  padding: 10px;
  font-size: 0.9rem;
  color: #333;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f9f9f9;
  margin-top: 4px; /* Aligns dropdown with label */
  margin-bottom: 16px;
}

.referred-archive-modal-select:focus {
  outline: none;
  border-color: #007bff;
  background-color: #fff;
}

/* Center the text inside the 'Other Carrier' input field */
.referred-archive-modal input[type="text"] {
  width: 90%; /* Adjust width as desired to ensure it's centered */
  padding: 10px;
  font-size: 0.9rem;
  color: #333;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f9f9f9;
  margin-top: 4px;
  margin-bottom: 16px;
  text-align: center; /* Center text within input */
}

.referred-archive-modal input[type="text"]:focus {
  outline: none;
  border-color: #007bff;
  background-color: #fff;
}

/* Button Styles */
.referred-archive-modal button {
  width: 48%;
  padding: 12px;
  font-size: 0.95rem;
  font-weight: 600;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 12px;
  display: inline-block;
  margin-right: 2%;
}

.referred-archive-modal button:last-of-type {
  margin-right: 0;
}

.referred-archive-modal button:hover {
  opacity: 0.9;
}

.referred-archive-modal button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Submit and Cancel Button Colors */
.referred-archive-modal-submit {
  background-color: #28a745; /* Green for Submit */
}

.referred-archive-modal-cancel {
  background-color: #dc3545; /* Red for Cancel */
}




/* Responsive Design */
@media (max-width: 768px) {
  .referred-quote-details {
    grid-template-columns: 1fr;
  }

  .referred-lead-actions {
    justify-content: center;
  }

  .referred-filter-container {
    width: 100%;
  }
}

/* Responsive Styles */
@media (max-width: 600px) {
  .referred-archive-modal {
    width: 90%;
    padding: 16px;
  }
  .referred-archive-modal-title {
    font-size: 1.2rem;
  }
  .referred-archive-modal button {
    width: 100%;
    font-size: 0.85rem;
    padding: 10px;
    margin-bottom: 8px;
  }
}

