/* LenderLogin.css */

.lender-login-container {
    width: 50%;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
}

#lender-login-h1 {
    text-align: center;
}

.lender-login-container form {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the form content horizontally */
}
  
.lender-login-container input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
    width: 80%;
    margin: 10px 0; /* Add margin to create a gap between inputs and the button */
}
  
.lender-login-container button {
    padding: 10px;
    border: none;
    border-radius: 3px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    font-size: 16px;
    width: 50%;
    margin: 20px 0 0 0; /* Add margin to create a gap between the button and the inputs */
}
  
.lender-login-container button:hover {
    background-color: #0056b3;
}
  
.error-message {
    color: red;
    margin-top: 10px;
    text-align: center;
}

/* Forgot Password Container */
.forgot-password-container {
    display: flex;
    justify-content: center; /* Center horizontally */
}

/* Forgot Password Button Styling */
.forgot-password-button {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    font-size: 14px;
    text-decoration: underline;
}
  
.forgot-password-button:hover {
    color: #0056b3;
}

.forgot-password-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    font-size: 18px;
    cursor: pointer;
}

.forgot-password-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    height: 350px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    z-index: 1000;
}
  
.forgot-password-modal form {
    display: flex;
    flex-direction: column;
}
  
.forgot-password-modal input {
    margin-bottom: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 3px;
}
  
.forgot-password-modal button {
    padding: 10px;
    border: none;
    border-radius: 3px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    font-size: 16px;
}
  
.forgot-password-modal button:hover {
    background-color: #0056b3;
}
  
.forgot-password-modal p {
    margin-top: 10px;
    text-align: center;
}
  
.forgot-password-modal .close-button {
    margin-top: 10px;
    background-color: #dc3545;
}
  
.forgot-password-modal .close-button:hover {
    background-color: #c82333;
}

@media screen and (max-width: 499px) {

    .lender-login-container {
        width: 70%;
    }

    #lender-login-h1 {
        font-size: 18px;;
    }

}