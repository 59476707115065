

.how-it-works-top {
    display: grid;
    grid-template: 1fr / 60% 40%;
}

.how-it-works-top img {
    grid-area: 1 / 1 / 1 / 3;
    width: 100%;
}

.how-it-works-top h1 {
    display: grid;
    grid-area: 1 / 1 / 1 / 1;
    color: #faf91a;
    font-size: 3vw;
    position: relative;
    padding-left: 5vw;
    align-self: center;
    bottom: 2vw;
    text-align: center;
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .how-it-works-top h1 {
        bottom: 0vw;
        padding-left: 7vw;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .how-it-works-top h1  {
        grid-area: 1 / 1 / 1 / 3;
        font-size: 4.5vw;
        width: 100%;
        margin: 0 auto;
        padding-left: 0px;
        bottom: 4vw;
        position: relative;
        
}
}

@media screen and (min-width: 576px) and (max-width: 767px) {
    .how-it-works-top h1  {
        grid-area: 1 / 1 / 1 / 3;
        font-size: 4.5vw;
        align-self: start;
        top: 6vw;
    }
}

@media screen and (min-width: 477px) and (max-width: 575px) {
    .how-it-works-top h1  {
        grid-area: 1 / 1 / 1 / 3;
        font-size: 4.5vw;
        align-self: start;
        top: 5vw;
    }
}

@media screen and (min-width: 300px) and (max-width: 476px) {
    .how-it-works-top h1  {
        grid-area: 1 / 1 / 1 / 3;
        font-size: 5.2vw;
        align-self: center;
        top: 0vw;
    }  
}