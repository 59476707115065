

.aboutus-plainsight {
    grid-template: 1fr 1fr 1fr / 1fr;
    
}

#aboutus-plainsight-head {
    text-align: center;
    font-size: 2vw;
}

#aboutus-plainsight-body {
    width: 90%;
    margin: 0 auto;
    font-size: 1.5vw;
    padding-bottom: 5vw;
}

.aboutus-plainsight-img {
    display: grid;
    grid-template: 1fr / 1fr 1fr 1fr;
    justify-content: center;
    width: 90%;
    margin: 0 auto;
    padding-bottom: 4vw;

}

.aboutus-plainsight-img img {
    display: grid;
    justify-self: center;
    width: 25vw;
    
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    #aboutus-plainsight-head {
        font-size: 2.5vw;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    #aboutus-plainsight-head {
        font-size: 3vw;
    }

    #aboutus-plainsight-body {
        font-size: 2.3vw;
    }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
    #aboutus-plainsight-head {
        font-size: 3vw;
    }

    #aboutus-plainsight-body {
        font-size: 3vw;
    }
}

@media screen and (min-width: 477px) and (max-width: 575px) {
    #aboutus-plainsight-head {
        font-size: 4vw;
    }

    #aboutus-plainsight-body {
        font-size: 4vw;
    }

    .aboutus-plainsight-img {
        grid-template: 1fr / 1fr;
    }

    #aboutus-plainsight-img1 {
        width: 90%;
    }

    #aboutus-plainsight-img2,
    #aboutus-plainsight-img3 {
        display: none;
    }
}

@media screen and (min-width: 300px) and (max-width: 476px) {
    #aboutus-plainsight-head {
        font-size: 5vw;
    }

    #aboutus-plainsight-body {
        font-size: 5vw;
    }

    .aboutus-plainsight-img {
        grid-template: 1fr / 1fr;
    }

    #aboutus-plainsight-img1 {
        width: 90%;
    }

    #aboutus-plainsight-img2,
    #aboutus-plainsight-img3 {
        display: none;
    }
}