


.header {
    display: block;
    width: 100%;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.18);   
    position: sticky;
    top: 0; 
    z-index: 999;
    background-color: white;
}

.burger-menu {
    display: none;
}

.wrapper {
    list-style-type: none;
    display: grid;
    width: 100%;
    margin: 0 auto;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr, 1fr, 1fr, 1fr, 1fr, 1fr;
    align-items: center;  
  
}

.nav {
    display: inline-flex;
    position: relative;
    grid-area: 1 / 2;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 15px;
    align-items: center;
}

.btn-purple {
    background-color: #7b0072;
    border: none;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    font-weight: bold;
    border-radius: 40px;
    transition: 0.15s;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 14px;
    padding-right: 14px;
    cursor: pointer;

}

.btn-purple:hover {
    background-color: #9b0091}

    
#navgetaquote {
    margin-right: 1rem;
    text-decoration: none;
}



#navaboutus {
    position: relative;
    margin-right: 1rem;
    text-decoration: none;
    color: #7b0072;
    font-family: 'Montserrat', sans-serif;
    border-right: 1px solid #ccc;
    padding: 6px 14px;
}

#navaboutus:hover {
    color: #9b0091;
}


#navhowitworks {
    position: relative;
    margin-right: 1rem;
    text-decoration: none;
    color: #7b0072;
    font-family: 'Montserrat', sans-serif;
    border-right: 1px solid #ccc;
    padding: 6px 14px;
}

#navhowitworks:hover {
    color: #9b0091;
}



#navresources {
    text-decoration: none;
    color: #7b0072;
    font-family: 'Montserrat', sans-serif;
    border-right: 1px solid #ccc;
    padding: 6px 14px;
}

.navdropdowncontent {
    display: none;
    position: absolute;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
    background-color: white;
    width: 200px; 
}

.navdropdowncontent a {
    text-decoration: none;
    display: block;
    color: #7b0072;
    font-family: 'Montserrat', sans-serif;
    padding: 10px 0px 10px 10px;
    
}

.navdropdown:hover .navdropdowncontent {
    display: block;
    z-index: 1;
}


#navresources:hover {
    color: #9b0091;
}

#navblog:hover,
#navfaq:hover,
#navforborkers:hover,
#navbrokerlogin:hover,
#navforlenders:hover {
    color: #9b0091;
}

#navtestimonials {
    position: relative;
    margin-right: 1rem;
    text-decoration: none;
    color: #7b0072;
    font-family: 'Montserrat', sans-serif;
    border-right: 1px solid #ccc;
    padding: 6px 14px;
}

#navtestimonials:hover {
    color: #9b0091;
}

#navcommunity {
    display: flex;
    position: relative;
    text-decoration: none;
    color: #7b0072;
    font-family: 'Montserrat', sans-serif;
}

#navcommunity:hover {
    color: #9b0091;
}

#headerlogo {
    display: inline-flex;
    height: 5rem;
    width: 5rem;
    grid-area: 1 / 1;
    position: relative;
    left: 4.5vw;

}

.sidebar {
    display: none; /* Initially hide the sidebar */
}

.sidebar.show {
    display: block; /* Show the sidebar when needed */
    position: fixed;
    top: 0;
    left: 0;
    width: 70%; /* Adjust width as needed */
    height: 100%;
    background-color: #f9f9f9;
    z-index: 1000;
    overflow-x: hidden;
    padding-top: 20px;
    transition: 0.5s;
}

.sidebar a {
    padding: 10px 15px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
}

.sidebar a:hover {
    color: #f1f1f1;
}



@media screen and (max-width: 1000px) {

    
    .wrapper {
        width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        
    }

    .nav {
        display: none; /* Hide original navigation links */
    }

    .sidebar {
        display: none; /* Show the sidebar */
    }

    .sidebar ul {
        list-style-type: none;
    }

    .sidebar.show {
        display: block; /* Show the sidebar when needed */
        position: fixed;
        top: 0;
        left: 0;
        width: 40%; /* Adjust width as needed */
        height: 100%;
        background-color: #f9f9f9;
        z-index: 1000;
        overflow-x: hidden;
        padding-top: 20px;
        transition: 0.5s;
    }

    .sidebar a {
        padding: 10px 15px;
        text-decoration: none;
        font-size: 20px;
        color: #7b0072;
        display: block;
    }
    
    .sidebar a:hover {
        color: #f1f1f1;
    }

    #headerlogo {
        display: block;
        margin: 0 auto;
    }

    .burger-menu {
        display: flex;
        border: none;
        background-color: white;
        position: relative;
        right: 45%;
        z-index: 1;
        font-size: 20px;
    }


    .nav.show {
        display: none; /* Show navigation links when burger menu is clicked */
        flex-direction: column; /* Stack navigation links vertically */
    }

    .nav-close-btn {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 32px;
        background: none;
        border: none;
        color: #555;
        cursor: pointer;
    }
    
    .close-btn:hover {
        color: #000;
    }

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
        z-index: 999; /* Ensure it appears above other content */
    }

    .btn-purple {
        position: relative;
        right: 30px;
    }

    #navaboutus {
        border: none;
        position: relative;
        right: 30px;
    }

    #navhowitworks {
        border: none;
        position: relative;
        right: 30px;
    }

    #navresources {
        border: none;
        position: relative;
        right: 30px;
    }
    .navdropdowncontent {
        position: relative;
        left: 15px;
    }

    #navtestimonials {
        right: 30px;
        border: none;
    }

    #navcommunity {
        right: 30px;
    }
}

@media screen and (max-width: 600px) {
    .sidebar.show {
        width: 65%;
    }

    .navdropdowncontent {
        width: 80%;
    }

    #navblog,
    #navfaq,
    #navforbrokers,
    #navbrokerlogin,
    #navforlenders {
        font-size: 16px;
    }
}
