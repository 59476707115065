#drag-drop-box-attached {
    list-style-type: none;
}

#drag-drop-box-list {
    line-height: 25px;
}

#drag-drop-box-choose-files {
    margin-right: 10px;
}

.drag-drop-box-header {
    display: flex;
    justify-content: space-around;

}




