* {
    font-family: 'Montserrat', sans-serif;
}

.faq-top{
    display: grid;
    background-color: #cadedd;
    grid-template: 1fr / 1fr;
    width: 100%;
}

.faq-top-img {
    grid-area: 1 / 1 / 1 / 1;
    display: flex;
    justify-self: center;
    align-self: end;
}

.faq-accordian-header {
    grid-area: 1 / 1 / 1 / 1;
    display: flex;
    justify-self: center;
    z-index: 1;
    position: relative;
    text-align: center;
    font-size: 24px;
    bottom: 20px;
}

.faq-accordian-header .frequently {
    color: #7b0372;
    font-weight: 900;
    font-family: 'Albert Sans', sans-serif;
}
.faq-accordian-header .asked {
    color: #efbb37;
    font-weight: 900;
    font-family: 'Albert Sans', sans-serif;
    padding: 0px 15px 0px 15px;
}

.faq-accordian-header .questions {
    color: #6c9c11;
    font-weight: 900;
    font-family: 'Albert Sans', sans-serif;
}




@media screen and (min-width: 1200px) and (max-width: 1300px) {


}

@media screen and (min-width: 992px) and (max-width: 1199px) {


}


@media screen and (min-width: 768px) and (max-width: 991px) {

}


@media screen and (min-width: 576px) and (max-width: 767px) {
    .faq-top-img {
        width: 100%;
    }

    .faq-accordian-header {
        font-size: 18px;
    }
            
}

@media screen and (min-width: 477px) and (max-width: 575px) {
    .faq-top-img {
        width: 100%;
    }

    .faq-accordian-header {
        font-size: 16px;
    }


}

@media screen and (min-width: 300px) and (max-width: 476px) {
    .faq-top-img {
        width: 100%;
    }
    
    .faq-accordian-header {
        font-size: 12px;
        bottom: 15px;
    }


}