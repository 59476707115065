

.customerexperiences-section {
    background-color: #f2fcfc;
    padding-top: 50px;
}

.customerexperiences-stars {
    display: flex;
    position: relative;
    justify-content: center;
}

.customerexperiences-stars img {
    position: relative;
    width: 30%;
    margin: 0 auto;
}

.customerexperiences-weworkwiththebest {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    width: 100%;
    margin: 0 auto;
}

.customerexperiences-weworkwiththebest a {
    font-size: 40px;
    font-weight: 700;
    padding: 25px 0px 25px 0px;
}

.customerexperiences-weworkwiththebest h2 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-style: italic;
    font-size: 30px;
    padding-bottom: 25px;
    text-align: center;

}

.customerexperiences-tiles {
    display: grid;
    grid-template: 50% 50% / 33% 33% 33%;
    grid-gap: 20px;
    position: relative;
    margin: 0 auto;
    width: 80%;
    padding-bottom: 50px;

}



.customerexperiences-tiles .tile {
    display: grid;
    grid-template: 1fr 1fr 1fr / 1fr 1fr;
    border: 1px solid #ececec;
    border-radius: 8px;
    box-shadow: 0px 1.5px 1.5px 0px rgb(0 0 0 / 20%);
    padding: 20px 40px 5px 40px;
    background-color: white;;
    
}

.tileNameProduct {
    display: flex;
    flex-direction: column;
}

.tile img {
    grid-area: 1 / 1 / 2 / 2;
    width: 100px;
    
}

.tileName {
    display: flex;
    grid-area: 1 / 2 / 1 / 2;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;

}

.tileProduct {
    display: flex;
    grid-area: 1 / 2 / 1 / 2;
    position: relative;
    font-family: 'Montserrat', sans-serif;
    

}

.tileTestimonial {
    grid-area: 2 / 1 / 4 / 3;
    position: relative;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .tileProduct {
        bottom: 2vw;
    }

    .tileTestimonial {
        font-size: 18px;
        bottom: 2vw;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .customerexperiences-stars img {
        width: 40vw;
    }

    .customerexperiences-weworkwiththebest h2 {
        font-size: 25px;
    }

    .customerexperiences-tiles {
        grid-template: 33% 33% 33% / 50% 50%;

        padding-bottom: 10vw;
    
    }

    .tileProduct {
        bottom: 3.2vw;
    }

}

@media screen and (min-width: 576px) and (max-width: 767px) {
    
    .customerexperiences-stars img {
        width: 40vw;
    }

    .customerexperiences-weworkwiththebest h2 {
        font-size: 20px;
        width: 90%;
    }
    

    .customerexperiences-tiles {
        grid-template: 33% 33% 33% / 50% 50%;
        padding-bottom: 10vw;   
    }

        .customerexperiences-tiles .tile {
            display: grid;
            grid-template: 18vw 14vw 1fr / 1fr;
            justify-items: center;
            
        }

        .tileNameProduct {
            grid-area: 2 / 1 / 2 / 1;
            
        }

        .tileName {
            margin: auto;
        }

        .tile img {
            display: block;
            grid-area: 1 / 1 / 1 / 1;
        }

 
        .tileProduct {
        bottom: 1.5vw;
    }

        .tileTestimonial {
            grid-area: 3 / 1 / 3 / 1;
            font-size: 17px;
        }
}

@media screen and (min-width: 477px) and (max-width: 575px) {
    .customerexperiences-stars img {
        width: 40vw;
    }

    .customerexperiences-weworkwiththebest h2 {
        font-size: 20px;
        width: 90%;
    }
    

    .customerexperiences-tiles {
        grid-template: 1fr 1fr 1fr / 1fr;
        padding-bottom: 10vw;
        }

        #toddf {
            display: none;
        }

        #maryb {
            display: none;
        }

        #mollys {
            display: none;
        }

        .customerexperiences-tiles .tile {
            display: grid;
            grid-template: 19vw 11vw 1fr / 1fr;
            justify-items: center;
            
        }

        .tileNameProduct {
            grid-area: 2 / 1 / 2 / 1;
            
        }

        .tileName {
            margin: auto;
        }

        .tile img {
            display: block;
            grid-area: 1 / 1 / 1 / 1;
        }

 
        .tileProduct {
        bottom: 1.5vw;
    }

        .tileTestimonial {
            grid-area: 3 / 1 / 3 / 1;
            font-size: 17px;
        }
}

@media screen and (min-width: 300px) and (max-width: 476px) {
    .customerexperiences-stars img {
        width: 45vw;
    
    }

    .customerexperiences-weworkwiththebest a {
        font-size: 28px;
    }

    .customerexperiences-weworkwiththebest h2 {
        font-size: 24px;
        width: 90%;
    }
    

    .customerexperiences-tiles {
        grid-template: 1fr 1fr 1fr / 1fr;
        padding-bottom: 10vw;
        width: 90%;
        }

        #toddf {
            display: none;
        }

        #maryb {
            display: none;
        }

        #mollys {
            display: none;
        }

        .customerexperiences-tiles .tile {
            display: grid;
            grid-template: 28vw 15vw 1fr / 1fr;
            justify-items: center;
            
        }

        .tileNameProduct {
            grid-area: 2 / 1 / 2 / 1;
            
        }

        .tileName {
            margin: auto;
        }

        .tile img {
            display: block;
            grid-area: 1 / 1 / 1 / 1;
        }

 
        .tileProduct {
        bottom: 1.5vw;
    }

        .tileTestimonial {
            grid-area: 3 / 1 / 3 / 1;
            font-size: 17px;
        }
}
