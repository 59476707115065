* {
    font-family: 'Montserrat', sans-serif;
}

.aboutus-top {
    display: grid;
    grid-template: 1fr / 50% 50%;
    border-bottom: 2vw solid rgb(173, 137, 213);
    
}

.aboutus-top h1 {
    grid-area: 1 / 1 / 1 / 3;
    position: relative;
    align-self: center;
    text-align: center;
    left: 8vw;
    font-size: 2.8vw;
    width: 60%;
}

.aboutus-top img {
    justify-self: end;
    grid-area: 1 / 2 / 1 / 3;
    width: 48vw;


}

#aboutus-top-RC {
    color: #7b0072;
}

#aboutus-top-BP {
    color: #6c9c11;

}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .aboutus-top h1 {
        font-size: 3.3vw;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .aboutus-top h1 {
        font-size: 3.3vw;
    }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
    .aboutus-top h1 {
        font-size: 3.5vw;
    }
}

@media screen and (min-width: 477px) and (max-width: 575px) {
    .aboutus-top {
        display: grid;
        grid-template: 50% 50% / 1fr;
        
        
    }

    .aboutus-top h1 {
        grid-area: 1 / 1 / 3 / 1;
        font-size: 6vw;
        justify-self: center;
        float: left;
        left:-15vw;
        text-align: left;
}

    .aboutus-top img {
        grid-area: 1 / 1 / 2 / 1;
        justify-self: center;
        width: 100%;
    }
}

@media screen and (min-width: 300px) and (max-width: 476px) {
    .aboutus-top {
        display: grid;
        grid-template: 50% 50% / 1fr;
        
        
    }

    .aboutus-top h1 {
        grid-area: 1 / 1 / 3 / 1;
        font-size: 6vw;
        justify-self: center;
        float: left;
        left:-15vw;
        text-align: left;
}

    .aboutus-top img {
        grid-area: 1 / 1 / 2 / 1;
        justify-self: center;
        width: 100%;
    }
}