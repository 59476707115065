/* Toggle Button */
.assigned-agent-archive-toggle-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 8px 12px;
    font-size: 0.9rem;
    cursor: pointer;
    margin-bottom: 16px;
  }
  
  .assigned-agent-archive-toggle-button:hover {
    background-color: #0056b3;
  }
  
  /* General Styling for Quote Archive List */
  .assigned-agent-quote-archive-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  /* Individual Quote Item Styling */
  .assigned-agent-archived-quote-item {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 16px;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  /* Quote Details Section */
  .assigned-agent-archived-quote-details p {
    font-size: 0.9rem;
    color: #333;
    margin: 6px 0;
  }
  
  .assigned-agent-archived-quote-details strong {
    color: #555;
    font-weight: 600;
  }
  
  /* Attached Files Styling */
  .assigned-agent-archived-quote-details a {
    color: #007bff;
    text-decoration: none;
  }
  
  .assigned-agent-archived-quote-details a:hover {
    text-decoration: underline;
  }
  
  /* Responsive Styles */
  @media (max-width: 600px) {
    .assigned-agent-archived-quote-item {
      padding: 16px;
    }
    .assigned-agent-archived-quote-details p {
      font-size: 0.85rem;
    }
  }
  