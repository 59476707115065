/* Container styling */
.lender-my-quotes-container {
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    color: #333;
}

.lender-my-quotes-container h2 {
    text-align: center;
    font-size: 2em;
    margin-bottom: 20px;
}

.broker-lender-quote-instructions {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 20px;
}

.broker-lender-quote-instructions h3 {
    margin-top: 0;
    font-size: 1.2em;
    color: #444;
}

/* Filter input styling */
.filter-container {
    text-align: center;
    margin-bottom: 20px;
}

.filter-input {
    width: 60%;
    max-width: 400px;
    padding: 10px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
}

/* Quote item styling */
.lender-quote-item-ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.lender-quote-item {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.lender-quote-details,
.lender-quote-status {
    flex: 1 1 45%;
    min-width: 200px;
}

.lender-quote-details p,
.lender-quote-status p {
    margin: 5px 0;
    font-size: 1em;
    color: #555;
}

/* Status and Payment Status Badges */
.status-badge, .payment-status-badge {
    padding: 5px 10px;
    border-radius: 12px;
    color: white;
    font-weight: bold;
    font-size: 0.9em;
}

.status-verified {
    background-color: #28a745;
}

.status-unverified {
    background-color: #6c757d;
}

.payment-paid {
    background-color: #28a745;
}

.payment-unpaid {
    background-color: #ffc107;
}

/* Button Styling */
.dashboard-blue-button, .lender-delete-archive-buttons {
    background-color: #007bff;
    color: white;
    padding: 8px 12px;
    font-size: 0.9em;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.dashboard-blue-button:hover, .lender-delete-archive-buttons:hover {
    background-color: #0056b3;
}

.lender-delete-archive-buttons-section {
    display: flex;
    gap: 10px;
    align-items: center;
}

/* Responsive styling */
@media screen and (max-width: 768px) {
    .lender-my-quotes-container {
        width: 90%;
    }

    .lender-quote-item {
        flex-direction: column;
    }

    .filter-input {
        width: 90%;
    }
}
