/* Modal backdrop to cover the entire viewport */
.payment-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000; /* Ensures the modal is above other elements */
  }
  
  /* Modal container */
  .payment-modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 500px; /* Limits the modal width */
    z-index: 2001;
    position: relative;
  }
  
  /* Modal header with title and close button */
  .payment-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  /* Close button styling */
  .payment-close-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  /* Modal body */
  .payment-modal-body {
    margin-top: 20px;
  }
  
  /* Input field styling */
  .payment-input {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  /* Button styling */
  .payment-submit-button {
    background-color: #007BFF;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    width: 100%;
  }

  .read-only {
    background-color: #f5f5f5;
    color: #666;
    cursor: not-allowed;
  }