/* IMPORTANT!  THIS FILE SERVERS AS THE MAIN CSS FILE FOR THE VARIOUS TYPES OF QUOTES.  ANY OVERLAPPING CLASSES AND ID'S ARE HANDLED HERE */

* {
    font-family: 'Albert Sans', sans-serif;
}

.homequoteinfo-contact {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: auto;    
    border: 1px solid rgb(165, 165, 165);
    box-shadow: 0px 1px 1px 0px;
    border-radius: 4px;
    width: 60%;
    margin: 0 auto;
    justify-content: center;
    background-color: white;
    height: auto;
    margin-top: 2.5vw;
    padding-bottom: 2.8vw;
    gap: 20px;
}


.first-last-name-inputs {
    display: flex;
    grid-area: 2 / 1 / 3 / 4;
    margin: 0 auto;
    justify-content: center;
}

.first-last-name-inputs input {
    height: 3vw;
    font-size: 18px;
}

#firstName, 
#lastName {
    width: 24vw;
}

#firstName {
    margin-right: 2vw;
}

#phoneNumber {
    display: grid;
    grid-area: 3 / 1 / 4 / 4;
    width: 51vw;
    height: 3vw;
    margin: 0 auto;
    font-size: 18px;
}

#email {
    grid-area: 4 / 1 / 5 / 4;
    width: 51vw;
    height: 3vw;
    margin: 0 auto;
    font-size: 18px;
}

.additionalNotes-textarea {
    grid-area: 5 / 1 / 6 / 4;
    display: grid;
    font-size: 18px;
    text-align: center;
}

#additionalNotes {
    width: 51vw;
    justify-self: center;
    height: 5vw;
    font-size: 1.1rem;
}

.agent-code-input {
    grid-area: 6 / 1 / 7 / 4;
    width: 51vw;
    display: flex;
    align-items: center;
    margin: 0 auto;
}

.agent-code-input label {
    margin-right: 10px;
    font-size: 18px;
}

.agent-code-input input {
    width: 200px; /* Adjust the width as needed */
    font-size: 18px;
}

.contact-what-happens-next {
    grid-area: 7 / 1 / 8 / 4;
    font-size: 18px;
    padding-bottom: 80px;
    width: 51vw;
    justify-self: center;
    margin: 0 auto;
}

#happens-next-bold {
    font-weight: bold;
}

.homequoteinfo-contact-abc {
    display: flex;
    flex-direction: row;
    grid-area: 1 / 1 / 2 / 4;
    justify-content: center;
    padding-bottom: 10px;
}

.contact-abc-a,
.contact-abc-b,
.contact-abc-c {
    display: flex;
    flex-direction: column;
    text-align: center;
    min-width: 15vw;
    padding-top: 1vw;
    position: relative;
    top: 2vw;
}

#contact-abc-a, 
#contact-abc-b,
#contact-abc-c {
    display: flex;
    margin: 0 auto;
    border-radius: 50%;
    height: 6vw;
    width: 6vw;
    justify-content: center;
    align-items: center;
    font-size: 1.8vw;
}

#contact-abc-c {
    background-color: #7b0072;
    font-weight: bold;
    color: white;
}

#contact-abc-contact {
    font-weight: bold;
    font-size: 1.8vw;
}

#contact-abc-address,
#contact-abc-birthdate {
    font-size: 1.8vw;
}

#contact-abc-a,
#contact-abc-b {
    background-color: #e0c3de;
    color: white;
}

  



@media screen and (min-width: 768px) and (max-width: 991px) {
    .homequoteinfo-contact {
        width: 80%;
        height: auto;
        gap: 10px;
    }

.first-last-name-inputs {
    width: 80%;
}

    #firstName, 
#lastName {
    width: 80%;
}


#phoneNumber {
    width: 79%;
}

#email {
    width: 79%;
}
.additionalNotes-textarea {
}

#additionalNotes {
    width: 79%;
    justify-self: center;
    height: 9vw;
}

.agent-code-input {
    width: 79%;
}

.contact-what-happens-next {
    padding-bottom: 50px;
    width: 79%;
}

}



@media screen and (min-width: 576px) and (max-width: 767px) {
    .homequoteinfo-contact {
        width: 80%;
        grid-auto-rows: auto;
        height: auto;
        padding-bottom: 10vw;
        gap: 10px;
                
    }

    .homequoteinfo-contact-abc {
    }

    .contact-abc-a, 
    .contact-abc-b,
    .contact-abc-c {
        width: 23vw;
    }

    #contact-abc-contact {
        font-size: 2.8vw;
    }
    
    #contact-abc-address,
    #contact-abc-birthdate {
        font-size: 2.8vw;
    }

    #contact-abc-a, 
    #contact-abc-b,
    #contact-abc-c {
        width: 10vw;
        height: 10vw;
        font-size: 5vw;
}

    .contactinputs {
        display: flex;
        flex-direction: column;
        grid-area: 2 / 1 / 3 / 2;
        justify-self: center;
        padding-top: 8vw;
    }

    .contactinputs input {
        width: 60vw;
    }

    .first-last-name-inputs {
        width: 80%;
    }
    
        #firstName, 
    #lastName {
        width: 80%;
    
    }
    
    
    #phoneNumber {
        width: 79%;
    }
    
    #email {
        width: 79%;
    }
    .additionalNotes-textarea {
    }
    
    #additionalNotes {
        width: 79%;
        justify-self: center;
        height: 9vw;
    }

    .agent-code-input {
        width: 79%;
    }
    
    .agent-code-input label {
        margin-right: 10px;
        font-size: 14px;
    }
    
    .agent-code-input input {
        width: 140px; /* Adjust the width as needed */
        font-size: 14px;
    }
    
    .contact-what-happens-next {
        padding-bottom: 40px;
        width: 79%;
    }

    .contact-what-happens-next, 
    .drag-drop-box-paragraph {
        font-size: 2.4vw;
    }

     .drag-drop-box {
        bottom: 5vw;
    }

    .file-upload-container {
        width: 90%;
        margin: 0 auto;
    }
}


@media screen and (min-width: 477px) and (max-width: 575px) {
    .homequoteinfo-contact {
        width: 80%;
        grid-auto-rows: auto;
        height: auto;
        padding-bottom: 10vw;
        gap: 10px;
        
    }

    .homequoteinfo-contact-abc {
    }

    .contact-abc-a, 
    .contact-abc-b,
    .contact-abc-c {
        width: 23vw;
    }

    #contact-abc-contact {
        font-size: 2.8vw;
    }
    
    #contact-abc-address,
    #contact-abc-birthdate {
        font-size: 2.8vw;
    }

    #contact-abc-a, 
    #contact-abc-b,
    #contact-abc-c {
        width: 10vw;
        height: 10vw;
        font-size: 5vw;
}

    .contactinputs {
        display: flex;
        flex-direction: column;
        grid-area: 2 / 1 / 3 / 2;
        justify-self: center;
        padding-top: 8vw;
        
    }

    .contactinputs input {
        width: 60vw;
    
    }

    .first-last-name-inputs {
        width: 80%;

    }
    
        #firstName, 
    #lastName {
        width: 80%;
        font-size: 3vw;
    
    }
    
    
    #phoneNumber {
        width: 79%;
        font-size: 3vw;
    }
    
    #email {
        width: 79%;
        font-size: 3vw;
    }
    .additionalNotes-textarea {
    }
    
    #additionalNotes {
        width: 79%;
        justify-self: center;
        height: 9vw;
        font-size: 12px;
    }

    .agent-code-input {
        width: 79%;
    }
    
    .agent-code-input label {
        margin-right: 5px;
        font-size: 12px;
    }
    
    .agent-code-input input {
        width: 140px; /* Adjust the width as needed */
        font-size: 12px;
    }
    
    .contact-what-happens-next {
        padding-bottom: 20px;
        width: 79%;
    }


    .contact-what-happens-next, 
    .drag-drop-box-paragraph {
        font-size: 2.4vw;
    }
}

@media screen and (min-width: 300px) and (max-width: 476px) {
    .homequoteinfo-contact {
        width: 80%;
        grid-auto-rows: auto;
        height: auto;
        padding-bottom: 10vw;
        gap: 10px;
    }

    .homequoteinfo-contact-abc {
    }

    .contact-abc-a, 
    .contact-abc-b,
    .contact-abc-c {
        width: 23vw;
    }

    #contact-abc-contact {
        font-size: 2.8vw;
    }
    
    #contact-abc-address,
    #contact-abc-birthdate {
        font-size: 2.8vw;
    }

    #contact-abc-a, 
    #contact-abc-b,
    #contact-abc-c {
        width: 10vw;
        height: 10vw;
        font-size: 5vw;
}

    .contactinputs {
        display: flex;
        flex-direction: column;
        grid-area: 2 / 1 / 3 / 2;
        justify-self: center;
        padding-top: 8vw;
        
    }

    .contactinputs input {
        width: 60vw;
    }

    .first-last-name-inputs {
        width: 80%;
    }
    
        #firstName, 
    #lastName {
        width: 80%;
        font-size: 12px;
    
    }
    
    
    #phoneNumber {
        width: 79%;
        font-size: 12px;
    }
    
    #email {
        width: 79%;
        font-size: 12px;
    }
    .additionalNotes-textarea {
    }
    
    #additionalNotes {
        width: 79%;
        justify-self: center;
        height: 9vw;
        font-size: 12px;
    }

    .agent-code-input {
        width: 79%;
    }
    
    .agent-code-input label {
        margin-right: 10px;
        font-size: 12px;
    }
    
    .agent-code-input input {
        width: 110px; /* Adjust the width as needed */
        font-size: 12px;
    }
    
    .contact-what-happens-next {
        padding-bottom: 20px;
        width: 79%;
    }

    .contact-what-happens-next, 
    .drag-drop-box-paragraph {
        font-size: 2.4vw;
    }
}

