/* Modal Overlay */
.ReactModal__Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75) !important;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  /* Modal Content */
  .ReactModal__Content {
    position: relative;
    width: 90%;
    background-color: #ffffff;
    padding: 20px 30px;
    border-radius: 8px;
    outline: none;
    max-height: 80vh;
    overflow-y: auto;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }
  
  /* Modal Header */
  .modal-header {
    margin-bottom: 20px;
    text-align: center;
    font-size: 24px;
    color: #333;
  }
  
  /* Referral Details */
  .referral-details {
    margin-bottom: 20px;
  }
  
  .referral-details p {
    margin: 10px 0;
    color: #555;
    line-height: 1.5;
  }
  
  .referral-details strong {
    color: #333;
  }
  
  .section-title {
    font-size: 20px;
    color: #007BFF;
    margin-bottom: 10px;
  }
  
  /* Buttons */
  .modal-button {
    display: inline-block;
    margin-top: 10px;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 6px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .close-button {
    background-color: #dc3545;
    color: #fff;
  }
  
  .close-button:hover {
    background-color: #c82333;
  }
  
  /* No Referral Details Message */
  .ReactModal__Content > p {
    text-align: center;
    color: #777;
    font-size: 18px;
  }
  
  /* Responsive Design */
  @media (max-width: 600px) {
    .ReactModal__Content {
      padding: 15px 20px;
    }
  
    .modal-button {
      width: 100%;
      margin-bottom: 10px;
    }
  }
  