.broker-auth-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.broker-auth-modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  width: 400px;
  max-width: 90%;
}

.broker-auth-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

input[type="email"] {
  width: 70%;
  padding: 10px;
  margin-right: 10px;
}

.are-you-independent-button {
  padding: 10px 20px;
  cursor: pointer;
  margin: 10px;
}

.google-signin-btn {
  background: none;
  border: none;
  cursor: pointer;
}

.close-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.error-message {
  color: red;
}
