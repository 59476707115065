.testimonialstop {
    position: relative;
    background-color: #f2fcfc;
    height: 27vw; /* Set height to full viewport height */
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
}

.testimonial-container {
    position: relative;
    overflow: hidden; /* Hide overflow */
    width: 60%;
}

#testimonials-stars {
    position: absolute;
    bottom: 3vw;
    width: 20%;
}

.testimonial-wrapper {
    display: flex;
    transition: transform 0.8s ease-in-out;
    
}

.testimonial {
    flex-shrink: 0;
    width: 100%; /* Adjust width as needed */
    text-align: center;
    color: black;
    margin: 0 auto; /* Center horizontally */
    font-size: 1.3vw;
    font-weight: bold;
    font-style: italic;
}

.arrow {
    position: absolute;
    top: 50%;
    font-size: 3.5rem;
    background: transparent;
    border: none;
    color: #efba33;
    cursor: pointer;
    z-index: 1;
    transform: translateY(-50%);
}

.arrow.prev {
    left: 12vw;
}

.arrow.next {
    right: 12vw;
}

.dots {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    bottom: 1vw;
}

.dot {
    width: 10px;
    height: 10px;
    background-color: #bbb;
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
}

.dot.active {
    background-color: #333;
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .testimonial {
        font-size: 1.6vw;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .testimonialstop {
        height: 38vw;
    }
    .testimonial {
        font-size: 2.2vw;
    }
    .arrow {
        display: none;
    }

    .testimonial-container {
        width: 80%;
        height: 18vw;
        
    }

    #testimonials-stars {
        width: 30vw;
        bottom: 15%;
    }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
    .testimonialstop {
        height: 48vw;
    }

        
    .testimonial {
        font-size: 2.8vw;
    }
    .arrow {
        display: none;
    }

    .testimonial-container {
        width: 90%;
        height: 35vw;
    }

    #testimonials-stars{
        width: 38vw;
        bottom: 15%;
        
    }
}

@media screen and (min-width: 477px) and (max-width: 575px) {
    .testimonialstop {
        height: 58vw;
    }

    .testimonial-container {
        height: 200px;
    }
    
    .testimonial {
        font-size: 3vw;
        position: relative;
    }
    .arrow {
        display: none;
    }

    .testimonial-container {
        width: 90%;
    }

    #testimonials-stars{
        width: 50vw;
        bottom: 13%;
        
    }
}

@media screen and (min-width: 300px) and (max-width: 476px) {
    .testimonialstop {
        height: 75vw;
    }

    .testimonial-container {
        height: 200px;
    }
    
    .testimonial {
        font-size: 4vw;
        position: relative;
    }
    .arrow {
        display: none;
    }

    .testimonial-container {
        width: 95%;
    }

    #testimonials-stars{
        width: 60vw;
        bottom: 14%;
        
    }
}