* {
    font-family: 'Montserrat', sans-serif;
}

.aboutus-corebeliefs {
    background-color: #f2fcfc;
    padding-top: 5vw;
    padding-bottom: 5vw;
}

#aboutus-corebeliefs-head {
    width: 90%;
    margin: 0 auto;
    font-size: 3vw;
}

#aboutus-corebeliefs-list {
    width: 90%;
    margin: 0 auto;
    font-size: 1.8vw;
    
}

#aboutus-corebeliefs-A {
    color: #7b0072;
    font-weight: 900;
    
}

#aboutus-corebeliefs-B {
    color: #efbb37;
    font-weight: 900;
}

#aboutus-corebeliefs-C {
    color: #6c9c11;
    font-weight: 900;

}

@media screen and (min-width: 768px) and (max-width: 991px) {
    #aboutus-corebeliefs-list {
        width: 90%;
        margin: 0 auto;
        font-size: 2.2vw;
        
    }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
    #aboutus-corebeliefs-head {
        font-size: 3.5vw;
    }
    
    #aboutus-corebeliefs-list {
        font-size: 3vw;
        
    }
}

@media screen and (min-width: 477px) and (max-width: 575px) {
    #aboutus-corebeliefs-head {
        text-align: center;
        font-size: 4.5vw;
    }
    
    #aboutus-corebeliefs-list {
        font-size: 4vw;
        
    }
}

@media screen and (min-width: 300px) and (max-width: 476px) {
    #aboutus-corebeliefs-head {
        text-align: center;
        font-size: 5.7vw;
    }
    
    #aboutus-corebeliefs-list {
        font-size: 4.5vw;
        
    }
}