
.getyougoing-section {
    display: grid;
    grid-template: 1fr 1fr / 1fr;
    width: 80%;
    margin: 0 auto;
    justify-items: center;
    padding-bottom: 50px;

}

.getyougoingsection-background {
    grid-area: 1 / 1 / 3 / 2;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-image: url('https://www.abcquote.com/wp-content/uploads/2023/10/on-your-way.png');
    background-size: cover;
    background-position: center;
}

.getyougoing-top {
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    font-size: 24px;
    grid-area: 1 / 1 / 1 / 1;
    color: white;
    position: relative;
}




/*GET YOU GOING BUTTON SECTION */
.getyougoing-buttons {
    grid-area: 2 / 1 / 2 / 1;
    width: 60%;
    position: relative;
    bottom: 150px;

}

#homeinsurancebutton {
    text-decoration: none;
    
}

.getyougoing-homeinsurancebutton {
    transition: box-shadow 0.3s;
    font-size: 21px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    padding: 10px 40px 10px 30px;
    border: 1px solid #ececec;
    border-radius: 8px;
    color: #7b0372;
    background-color: #fff;
    width: 100%;
    text-decoration: none;
    cursor: pointer;
    margin: 20px 0px 20px 0px;
    box-shadow: 0px 1.5px 1.5px 0px rgb(0 0 0 / 20%);
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    
}

.getyougoing-homeinsurancebutton:hover {
    color: black;
    box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 20%);
}

.getyougoing-homeinsurancebutton img {
    width: 50px;
    position: relative;

    
}

.getyougoing-homeinsurancebutton p {
    position: relative;
}


.getyougoing-homeinsurancebutton span {
    font-size: 40px;
    position: relative;

}

#autoinsurancebutton {
    text-decoration: none;
}

.getyougoing-autoinsurancebutton {
    transition: box-shadow 0.3s;
    font-size: 21px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    padding: 10px 40px 10px 30px;
    border: 1px solid #ececec;
    border-radius: 8px;
    box-shadow: 0px 1.5px 1.5px 0px rgb(0 0 0 / 20%);
    color: #7b0372;
    background-color: #fff;
    width: 100%;
    cursor: pointer;
    margin: 20px 0px 20px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}

.getyougoing-autoinsurancebutton:hover {
    color: black;
    box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 20%);
}

.getyougoing-autoinsurancebutton img {
    width: 40px;
    position: relative;
    

}

.getyougoing-autoinsurancebutton span {
    font-size: 40px;
    position: relative;
    color: #efba33;

}

#bundlebutton {
    text-decoration: none;
}

.getyougoing-bundlebutton {
    transition: box-shadow 0.3s;
    font-size: 21px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    padding: 10px 40px 10px 30px;
    border: 1px solid #ececec;
    border-radius: 8px;
    box-shadow: 0px 1.5px 1.5px 0px rgb(0 0 0 / 20%);
    color: #7b0372;
    background-color: #fff;
    cursor: pointer;
    margin: 20px 0px 20px 0px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    width: 100%;
    z-index: 1;
}

.getyougoing-bundlebutton:hover {
    color: black;
    box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 20%);
}

.getyougoing-bundlebutton img {
    width: 40px;
    position: relative;
    float: left;
}

.getyougoing-bundlebutton p {
    word-wrap: break-word;
    padding: 0px 20px 0px 20px;
}

.getyougoing-bundlebutton span {
    font-size: 40px;
    position: relative;
    float: right;
    color: #6a9a07;
}


@media screen and (min-width: 768px) and (max-width: 991px) {
    .getyougoing-top {
        width: 90%;
    }

}

@media screen and (min-width: 576px) and (max-width: 767px) {
    .getyougoing-section {
        width: 100%;
    }
    
    .getyougoing-top {
        width: 90%;
        top: 5vw;
    }

    .getyougoing-buttons {
        width: 90%;
        bottom: 15vw;
    }
}

@media screen and (min-width: 477px) and (max-width: 575px) {
    .getyougoing-section {
        width: 100%;
    }

    .getyougoing-top {
        width: 90%;
        top: 5vw;
    }

    .getyougoing-buttons {
        width: 90%;
        bottom: 15vw;
    }
}

@media screen and (min-width: 300px) and (max-width: 476px) {
    
    .getyougoing-section {
        width: 100%;
    }

    .getyougoing-top {
        width: 90%;
        top: 5vw;
    }

    .getyougoing-buttons {
        width: 90%;
        bottom: 5vw;
    }

    .getyougoing-autoinsurancebutton,
    .getyougoing-bundlebutton,
    .getyougoing-homeinsurancebutton {
        font-size: 15px;
    }
}
