.captive-purchased-leads {
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  .captive-purchased-leads h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .monthly-section {
    margin-bottom: 30px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 20px;
  }
  
  .monthly-section h3 {
    margin-top: 0;
    color: #333;
  }
  
  .lead-list {
    list-style: none;
    padding-left: 0;
    margin-top: 15px;
  }
  
  .lead-item {
    background-color: #fff;
    border: 1px solid #ddd;
    margin-bottom: 10px;
    padding: 15px;
    border-radius: 5px;
  }
  
  .lead-item p {
    margin: 5px 0;
  }
  
  .toggle-button {
    background-color: #007bff;
    color: white;
    padding: 6px 12px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    margin-top: 10px;
  }
  
  .toggle-button:hover {
    background-color: #0056b3;
  }
  