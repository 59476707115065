/* resetPassword.css */

.reset-password-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .reset-password-container h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .reset-password-container form {
    display: flex;
    flex-direction: column;
  }
  
  .reset-password-container label {
    margin-bottom: 5px;
  }
  
  .reset-password-container input {
    margin-bottom: 15px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .reset-password-container button {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .reset-password-container button:hover {
    background-color: #0056b3;
  }
  
  .reset-password-container p {
    text-align: center;
  }
  