/* Modal Overlay */
.ReactModal__Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75) !important;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  /* Modal Content */
  .ReactModal__Content {
    position: relative;
    width: 90%;
    max-width: 600px;
    background-color: #ffffff;
    padding: 20px 30px;
    border-radius: 8px;
    outline: none;
    max-height: 90vh;
    overflow-y: auto;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }
  
  /* Modal Header */
  .modal-header {
    margin-bottom: 20px;
    text-align: center;
    font-size: 24px;
    color: #333;
  }

  /* Filter Input */
.filter-input {
    width: 100%;
    padding: 8px 12px;
    margin-bottom: 20px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  
  /* Agent Card */
  .agent-card {
    border: 1px solid #ddd;
    border-radius: 6px;
    padding: 20px;
    margin-bottom: 20px;
    background-color: #f9f9f9;
  }
  
  .agent-card h3 {
    margin-top: 0;
    font-size: 22px;
    color: #007BFF;
  }
  
  .agent-card p {
    margin: 5px 0;
    color: #555;
    line-height: 1.5;
  }
  
  /* Carrier List */
  .carrier-list {
    margin: 10px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center; /* Align items vertically */
  }
  
  /* Remove bullet points from carrier list */
  /* If using ul/li elements, remove bullet points */
  /* Since we switched to divs and labels, this may not be needed */
  .agent-card ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  
  /* Carrier Label */
  .carrier-label {
    display: inline-flex; /* Use inline-flex to prevent labels from stretching */
    align-items: center;
    margin-right: 15px;
    margin-bottom: 10px;
    cursor: pointer;
    color: #333;
    font-size: 16px;
    line-height: normal;
    padding: 0;
  }
  
  /* Checkbox Input */
  .carrier-label input[type='checkbox'] {
    margin-right: 5px;
    vertical-align: middle;
    padding: 0;
    margin: 0;
  }
  
  /* Buttons */
  .modal-button {
    display: inline-block;
    margin-top: 15px;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 6px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .send-button {
    background-color: #28a745;
    color: #fff;
  }
  
  .send-button:hover {
    background-color: #218838;
  }
  
  .send-button:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
  }
  
  .close-button {
    background-color: #dc3545;
    color: #fff;
  }
  
  .close-button:hover {
    background-color: #c82333;
  }
  
  /* Spinner Overlay */
  .spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Responsive Design */
  @media (max-width: 600px) {
    .ReactModal__Content {
      padding: 15px 20px;
    }
  
    .agent-card {
      padding: 15px;
    }
  
    .modal-button {
      width: 100%;
      margin-bottom: 10px;
    }
  
    .carrier-label {
      width: 100%;
    }
  }
  