
.Community-Body {
    width: 80%;
    margin: 0 auto;
}

.Community-Body h2 {
    text-align: center;
}

.Community-Body p {
    font-size: 20px;
    line-height: 1.5;
}