.authentication-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    height: 100%;
    width: 100%;
  
  }
  
  .authentication-modal {
    background: white;
    padding: 20px;
    border-radius: 5px;
    position: relative;
    width: 50%;
    
  
  }
  
  .authentication-modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }

.loginInputsButtons {
  display: grid;
  grid-auto-rows: auto;
  gap: 20px;
  justify-items: center;
  
  
}

#loginInputHeader {
  grid-area: 1 / 1 / 2 / 3;
}


#authInputEmail {
  grid-area: 2 / 1 / 3 / 2;
  width: 80%;
}

#authInputPassword {
  grid-area: 2 / 2 / 3 / 3;
  width: 80%;

}

#authButtonLogin {
  grid-area: 3 / 1 / 4 / 2;
  width: 60%;
  cursor: pointer;

}

#authButtonNewUser {
  grid-area: 3 / 2 / 4 / 3;
  width: 60%;
  cursor: pointer;

}

#authButtonGoogle {
  grid-area: 4 / 1 / 5 / 3;
  display: inline-block;
  background-color: #ffffff;
  color: #757575;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  border: 1px solid lightgrey;
  font-size: 14px;
  font-weight: 500;
  width: 40%;
  height: 36px;
  padding: 0 12px;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s, box-shadow 0.3s;
}

#authButtonGoogle:hover {
  background-color: #f8f8f8;
}

#authButtonGoogle:focus {
  outline: none;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1), 0 0 0 4px rgba(66, 133, 244, 0.25);
}

#authButtonGoogle:active {
  background-color: #f8f8f8;
  border: 1px solid #ccc;
}

#authButtonGoogle .google-icon {
  background-image: url('https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg');
  background-size: cover;
  width: 18px;
  height: 18px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 12px;
}

#authButtonLogout {
  grid-area: 5 / 1 / 6 / 3;
  width: 40%;
  cursor: pointer;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .authentication-modal {
    width: 80%;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .authentication-modal {
    width: 90%;
  }
}

@media screen and (min-width: 477px) and (max-width: 575px) {
  .authentication-modal {
    width: 90%;
  }

  #authButtonGoogle {
    height: 40px;
    width: 50%;
  }
}

@media screen and (min-width: 300px) and (max-width: 476px) {
  .authentication-modal {
    width: 90%;
  }

  #authButtonGoogle {
    height: 40px;
    width: 60%;
  }

}