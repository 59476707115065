/* Basic reset */

.new-customer-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  background-color: #f8f9fa;
}

.new-customer-form {
  background: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.new-customer-form h2 {
  text-align: center;
  margin-bottom: 20px;
}

.lender-quote-address-sections,
.lender-quote-homeowner-sections {
  display: flex;
  justify-content: space-between;
}

.lender-quote-submit-section {
  flex: 1;
  margin-right: 20px;
  padding: 10px;
}

.lender-quote-submit-section:last-child {
  margin-right: 0;
}

.lender-quote-section-label {
  font-weight: bold;
  margin-bottom: 10px;
  display: block;
  text-align: center;
}

.new-customer-form label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.new-customer-form input,
.new-customer-form textarea,
.new-customer-form select {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
}

.new-customer-form input:focus,
.new-customer-form textarea:focus,
.new-customer-form select:focus {
  border-color: #007bff;
  outline: none;
}

.new-customer-form button {
  width: 60%;
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin: 0 auto;
  display: block;
}

.new-customer-form button:hover {
  background-color: #0056b3;
}

.new-customer-form .textarea {
  height: 100px;
}

.lender-lead-submit-button {
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.lender-lead-submit-button:hover {
  background-color: #0056b3;
}

/* Styles for QuoteInfoFileUpload */

.file-upload-container {
  border: 2px dashed #007bff;
  padding: 20px;
  text-align: center;
  margin: 20px 0;
  border-radius: 10px;
  background-color: #f8f9fa;
}

.file-upload-container.drag-over {
  background-color: #e6f0ff;
}

#drag-drop-box-choose-files,
#drag-drop-box-upload {
  margin: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

#drag-drop-box-choose-files:hover,
#drag-drop-box-upload:hover {
  background-color: #0056b3;
}

#drag-drop-box-attached {
  list-style-type: none;
  padding: 0;
}

#drag-drop-box-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
}

#drag-drop-box-remove {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

#drag-drop-box-remove:hover {
  background-color: #c82333;
}

.error {
  color: #dc3545;
  margin-top: 10px;
}

#drag-drop-box-sign-in,
#uploadButtonLogout {
  display: none;
}

.dashboard-buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.dashboard-blue-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dashboard-blue-button:hover {
  background-color: #0056b3;
}

@media screen and (max-width: 550px) {
  .lender-quote-address-sections, 
  .lender-quote-homeowner-sections {
    flex-direction: column;
  }

  .lender-quote-submit-section {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .new-customer-form h2 {
    font-size: 20px;
  }

  .new-customer-form-container {
    width: 98%;
  }
}
