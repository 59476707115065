

.savingscoverageconvenience {
    display: flex;
    position: relative;
    margin: 0 auto;
    width: 80%;
    justify-content: center;
    bottom: 80px;
    
}

.savingscoverageconvenience .savings {
    color: #7b0372;
    font-weight: 900;
    font-size: 4vw;
    font-family: 'Albert Sans', sans-serif;
}

.savingscoverageconvenience .coverage {
    color: #efbb37;
    font-weight: 900;
    font-size: 4vw;
    font-family: 'Albert Sans', sans-serif;
    padding: 0px 15px 0px 15px;
}

.savingscoverageconvenience .convenience {
    color: #6c9c11;
    font-weight: 900;
    font-size: 4vw;
    font-family: 'Albert Sans', sans-serif;
}



.accordion {
    box-sizing: border-box;
    border: 3px solid black;
    border-style: none;
    width: 60%;
    margin: 0 auto;
    position: relative;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    overflow: hidden;
    font-family: 'Albert Sans', sans-serif;
    background: white;
    font: black;
    bottom: 90px;
    padding-bottom: 15px;
    }
  #right {
    text-align: right;
  }
    
  #logo {
    position: relative;
    height: 120px;
    width: 111px;
    text-align: right;
    right: 170px;
    top: 45px;
  }
  
  #online {
    position: relative;
    text-align: right;
    right: 85px;
    bottom: 50px;
    font-family: 'Albert Sans', sans-serif;
    font-weight: bold;
    font-size: 20px;
    }
  
  .checkmark {
    float: right;
    width: 25px;
    height: 20px;
    display: inline-block;
    position: relative;
    right: 95px;
  }
  
  .xmark {
    float: right;
    position: relative;
    right: 30px;
    width: 15px;
    height: 15px;
  }
  
  .accordion__label,
  .accordion__content {
    padding: 14px 20px;
    
  }
  
  .accordion__label {
    display: block;
    color: black;
    font-weight: 550;
    font-size: 23px;
    cursor: pointer;
    position: relative;
    transition: background 0.1s;
    }
  
  .accordion__label:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  
  .accordion__label::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    width: 12px;
    height: 6px;
    background-image: url('data:image/svg+xml;utf8,<svg width="100" height="50" xmlns="http://www.w3.org/2000/svg"><polygon points="0,0 100,0 50,50" style="fill:gray;" /></svg>');
    background-size: contain;
    transition: transform 0.4s;
  }
  
  .accordion__content {
    background: rgba(123, 3, 114, 0.2);
    line-height: 1.6;
    font-size: 18px;
    display: none;
    border-radius: 4px;
    width: 90%;
    margin: 0 auto;
  
  }
  
  .accordion__input {
    display: none;
  }
  
  .accordion__input:checked ~ .accordion__content {
    display: block;
  }
  
  .accordion__input:checked ~ .accordion__label::after {
    transform: translateY(-50%) rotate(0.5turn);
  }

  
  @media screen and (min-width: 992px) and (max-width: 1199px) {

    .accordion {
      width: 70%;
      bottom: 60px;
    }

    .savingscoverageconvenience {
      bottom: 60px;
    }
  }


  @media screen and (min-width: 768px) and (max-width: 991px) {

    .accordion {
      width: 80%;
      bottom: 50px;
    }

    .savingscoverageconvenience {
      bottom: 60px;
    }

    .savingscoverageconvenience .savings,
    .savingscoverageconvenience .coverage,
    .savingscoverageconvenience .convenience {
      font-weight: 600;
      font-size: 40px;
    }

  }

  @media screen and (min-width: 576px) and (max-width: 767px) {

    .accordion {
      width: 90%;
      
    }

    #logo {
      height: 100px;
      width: 92px;
      right: 179px;
    }

    .savingscoverageconvenience {
      bottom: 80px;
    }

    .savingscoverageconvenience .savings,
    .savingscoverageconvenience .coverage,
    .savingscoverageconvenience .convenience {
      font-weight: 600;
      font-size: 30px;
    }

  }

  @media screen and (min-width: 477px) and (max-width: 575px) {

    .accordion {
      width: 90%;
      
    }


    .checkmark {
      right: 12vw;
    }

    .xmark {
      right: 3vw;
      top: .3vw;
    }

    #online {
      font-size: 15px;
      right: 12vw;
      bottom: 4vw;
    }

    #logo {
      height: 70px;
      width: 65px;
      right: 24vw;
    }

    .savingscoverageconvenience {
      bottom: 80px;
    }

    .savingscoverageconvenience .savings,
    .savingscoverageconvenience .coverage,
    .savingscoverageconvenience .convenience {
      font-weight: 600;
      font-size: 30px;
    }

  }

  @media screen and (min-width: 300px) and (max-width: 476px) {

    .accordion {
      width: 90%;
      bottom: 60px;
      
    }

    .accordion__label {
      font-size: 4.5vw;
    }

    .checkmark {
      right: 12vw;
    }

    .xmark {
      right: 2.3vw;
      top: .3vw;
    }

    #online {
      font-size: 15px;
      right: 12vw;
      bottom: 4vw;
    }

    #logo {
      height: 70px;
      width: 65px;
      right: 24vw;
    }

    .savingscoverageconvenience {
      bottom: 80px;
    }

    .savingscoverageconvenience .savings,
    .savingscoverageconvenience .coverage,
    .savingscoverageconvenience .convenience {
      font-weight: 600;
      font-size: 6vw;
    }

  }