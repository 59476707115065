* {
    font-family: 'Albert Sans', sans-serif;
}

.autoquoteinfo-contact {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: auto;
    border: 1px solid rgb(165, 165, 165);
    box-shadow: 0px 1px 1px 0px;
    border-radius: 4px;
    width: 60%;
    margin: 0 auto;
    justify-content: center;
    background-color: white;
    height: auto;
    margin-top: 2.5vw;
    padding-bottom: 2.8vw;
    gap: 20px;

}


.autoquoteinfo-contact-abc {
    display: flex;
    flex-direction: row;
    grid-area: 1 / 1 / 2 / 4;
    justify-content: center;
    padding-bottom: 10px;
    
}


#auto-contact-abc-a, 
#auto-contact-abc-b,
#auto-contact-abc-c {
    display: flex;
    margin: 0 auto;
    border-radius: 50%;
    height: 6vw;
    width: 6vw;
    justify-content: center;
    align-items: center;
    font-size: 1.8vw;
      
}

#auto-contact-abc-c {
    background-color: #efba33;
    font-weight: bold;
    color: white;
}

#auto-contact-abc-contact {
    font-weight: bold;
    font-size: 1.8vw;
}

#auto-contact-abc-address,
#auto-contact-abc-birthdate {
    font-size: 1.8vw;
}

#auto-contact-abc-a,
#auto-contact-abc-b {
    background-color: #ffe091;
    color: white;

}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .autoquoteinfo-contact {
        width: 80%;
        height: auto;

    }

}



@media screen and (min-width: 576px) and (max-width: 767px) {
    .autoquoteinfo-contact {
        width: 80%;
        grid-auto-rows: auto;
        height: auto;
        padding-bottom: 10vw;
        
    }

    .autoquoteinfo-contact-abc {
    }


    #auto-contact-abc-contact {
        font-size: 2.8vw;
    }
    
    #auto-contact-abc-address,
    #auto-contact-abc-birthdate {
        font-size: 2.8vw;
    }

    #auto-contact-abc-a, 
    #auto-contact-abc-b,
    #auto-contact-abc-c {
        width: 10vw;
        height: 10vw;
        font-size: 5vw;
}

}

@media screen and (min-width: 477px) and (max-width: 575px) {
    .autoquoteinfo-contact {
        width: 80%;
        grid-auto-rows: auto;
        height: auto;
        padding-bottom: 10vw;
        
    }

    .autoquoteinfo-contact-abc {
    }

    #auto-contact-abc-contact {
        font-size: 2.8vw;
    }
    
    #auto-contact-abc-address,
    #auto-contact-abc-birthdate {
        font-size: 2.8vw;
    }

    #auto-contact-abc-a, 
    #auto-contact-abc-b,
    #auto-contact-abc-c {
        width: 10vw;
        height: 10vw;
        font-size: 5vw;
}

}

@media screen and (min-width: 300px) and (max-width: 476px) {
    .autoquoteinfo-contact {
        width: 80%;
        grid-auto-rows: auto;
        height: auto;
        padding-bottom: 10vw;
        gap: 10px;
        
    }

    .autoquoteinfo-contact-abc {
    }

    #auto-contact-abc-contact {
        font-size: 2.8vw;
    }
    
    #auto-contact-abc-address,
    #auto-contact-abc-birthdate {
        font-size: 2.8vw;
    }

    #auto-contact-abc-a, 
    #auto-contact-abc-b,
    #auto-contact-abc-c {
        width: 10vw;
        height: 10vw;
        font-size: 5vw;
}
}


