.archived-quotes-container {
    width: 70%;
    margin: 0 auto;
}

.archived-quotes-container h2 {
    text-align: center;
}

.dashboard-blue-button {
    width: 220px;
    margin-left: 20px;
}

.archived-quote-item {
    display: grid;
    grid-template: 1fr / 1fr 1fr 1fr;
    list-style: none;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.archived-quote-details {
    grid-area: 1 / 1 / 2 / 2;
    align-self: center;
}

.archived-quote-status {
    grid-area: 1 / 2 / 2 / 3;
    align-self: center;
    justify-self: start;
    padding-left: 20px;
}

/* Status and Payment Status Styling */
.status-badge, .payment-status-badge {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 5px;
    color: white;
    font-size: 16px;
    position: relative;
}

.status-verified {
    background-color: green;
}

.status-unverified {
    background-color: grey;
}

.payment-paid {
    background-color: green;
}

.payment-unpaid {
    background-color: grey;
}

.archived-delete-archive-buttons {
    margin-left: 5px;
}
