
.blog-entire-page {
    padding-bottom: 5vw;
}


/*TEXT BLOG SECTION*/

.blog-articles-section ul {
    display: grid;
    width: 80%;
    margin: 0 auto;
    grid-template-columns: repeat(auto-fill, minmax(20vw, 1fr));
    grid-auto-rows: auto; /* Let rows adjust based on content */
    grid-gap: 10px;
    list-style: none;
    padding: 0;
}

.blog-articles-header {
    text-align: center;
}

.blog-list-item {
    display: grid;
    grid-template-rows: auto auto auto auto; /* Adjust rows to auto-height */
    background-color: rgb(236, 234, 234);
    border: 1px solid lightgrey;
    border-radius: 4px;
    padding: 10px; /* Add some padding for better spacing */
}

.blog-list-item a {
    text-decoration: none;
    color: black;
    display: grid; /* Ensure the link takes the full space and acts as a grid */
    grid-template-rows: auto auto 1fr auto; 
}

.blog-list-img {
    width: 100%;
    height: auto; /* Maintain aspect ratio */
}

.blog-list-title {
    text-align: center;
    padding: 4px;
    font-size: 1.2em; /* Adjust as needed */
}

.blog-list-synopsis {
    text-align: center;
    padding: 4px;
}

.blog-list-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F4524D;
    color: white;
    font-weight: 600;
    font-size: 1vw;
    border: none;
    padding: 10px 20px; /* Adjust padding for better button size */
    margin: 0 auto; /* Center the button within its container */
    text-decoration: none; /* Ensure no underline */
    cursor: pointer;
}




/*VIDEO BLOG SECTION*/

.video-library-header {
    display: flex;
    background-color: lightgrey;
    width: 80%;
    margin: 0 auto;
    margin-top: 4vw;
    border-radius: 4px;
    height: 3vw;
    align-items: center;
    justify-content: center;

}

.blog-video-library ul {
    display: grid;
    width: 80%;
    margin: 0 auto;
    grid-template-columns: repeat(auto-fill, minmax(20vw, 1fr));
    grid-auto-rows: auto; /* Let rows adjust based on content */
    grid-gap: 10px;
    list-style: none;
    padding: 0;
    padding-top: 10px;
}

.blog-articles-header {
    text-align: center;
}

.blog-video-item {
    display: grid;
    grid-template-rows: auto auto auto auto; /* Adjust rows to auto-height */
    background-color: rgb(236, 234, 234);
    border: 1px solid lightgrey;
    border-radius: 4px;
    padding: 10px; /* Add some padding for better spacing */
}

.blog-video-item a {
    text-decoration: none;
    color: black;
    display: grid; /* Ensure the link takes the full space and acts as a grid */
    grid-template-rows: auto auto 1fr auto; 
}

.blog-video-img {
    width: 100%;
    height: auto; /* Maintain aspect ratio */
}

.blog-video-title {
    text-align: center;
    padding: 4px;
    font-size: 1.2em; /* Adjust as needed */
}

.blog-video-synopsis {
    text-align: center;
    padding: 4px;
}

.blog-video-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F4524D;
    color: white;
    font-weight: 600;
    font-size: 1vw;
    border: none;
    padding: 10px 20px; /* Adjust padding for better button size */
    margin: 0 auto; /* Center the button within its container */
    text-decoration: none; /* Ensure no underline */
    cursor: pointer;
}




@media screen and (min-width: 992px) and (max-width: 1199px) {
    /*text article section*/

    .blog-articles-header {
        display: flex;
        width: 100%;
        font-size: 1.3vw;
        justify-content: center;
    }

    .blog-video-button,
    .blog-list-button {
        font-size: 1.5vw;
    }

    /*video section*/

    .video-library-header {
        height: 4vw;
    }


}

@media screen and (min-width: 768px) and (max-width: 991px) {
        /*text article section*/

        .blog-articles-header {
            display: flex;
            width: 100%;
            font-size: 1.5vw;
            justify-content: center;
        }

        .blog-articles-section ul {
            width: 90%;
            grid-template-columns: repeat(auto-fill, minmax(30vw, 1fr));

        }

        /*video section*/

        .video-library-header {
            width: 90%;
            height: 5vw;
        
        }
        
        .blog-video-library ul {
            width: 90%;
            grid-template-columns: repeat(auto-fill, minmax(30vw, 1fr));

        }

        .blog-video-button,
        .blog-list-button {
            font-size: 1.8vw;
        }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
            /*text article section*/

            .blog-articles-header {
                display: flex;
                width: 100%;
                font-size: 1.8vw;
                justify-content: center;
            }
    
            .blog-articles-section ul {
                width: 90%;
                grid-template-columns: repeat(auto-fill, minmax(30vw, 1fr));
    
            }
    
            /*video section*/
    
            .video-library-header {
                width: 90%;
                height: 5vw;
                font-size: 3.5vw;
            
            }
            
            .blog-video-library ul {
                width: 90%;
                grid-template-columns: repeat(auto-fill, minmax(30vw, 1fr));
    
            }
    
            .blog-video-button,
            .blog-list-button {
                font-size: 2.1vw;
            }
}


@media screen and (min-width: 477px) and (max-width: 575px) {
                /*text article section*/

                .blog-articles-header {
                    display: flex;
                    width: 100%;
                    font-size: 1.8vw;
                    justify-content: center;
                }
        
                .blog-articles-section ul {
                    width: 90%;
                    grid-template-columns: repeat(auto-fill, minmax(80vw, 1fr));
        
                }
        
                /*video section*/
        
                .video-library-header {
                    width: 90%;
                    height: 5vw;
                    font-size: 3.5vw;
                
                }
                
                .blog-video-library ul {
                    width: 90%;
                    grid-template-columns: repeat(auto-fill, minmax(80vw, 1fr));
        
                }
        
                .blog-video-button,
                .blog-list-button {
                    font-size: 2.1vw;
                }
}

@media screen and (min-width: 300px) and (max-width: 476px) {
                /*text article section*/

                .blog-articles-header {
                    display: flex;
                    width: 100%;
                    font-size: 2.4vw;
                    justify-content: center;
                }
        
                .blog-articles-section ul {
                    width: 90%;
                    grid-template-columns: repeat(auto-fill, minmax(80vw, 1fr));
        
                }
        
                /*video section*/
        
                .video-library-header {
                    width: 90%;
                    height: 5vw;
                    font-size: 3.5vw;
                
                }
                
                .blog-video-library ul {
                    width: 90%;
                    grid-template-columns: repeat(auto-fill, minmax(80vw, 1fr));
        
                }
        
                .blog-video-button,
                .blog-list-button {
                    font-size: 3.4vw;
                }
}