/* --- Your existing CSS --- */

/* CaptiveDashboard Container */
.captive-dashboard {
  padding: 20px;
  background-color: #f5f7fa;
  min-height: 100vh;
  font-family: Arial, sans-serif;
}

/* Dashboard Header */
.captive-dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 2px solid #007bff;
}

#captive-dashboard-title {
  font-size: 2rem;
  color: #333;
}

/* Additional styles for chunky squares in the lead details */
.captive-quote-square {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 6px;
  margin-bottom: 15px;
  /* Added properties to ensure text wraps */
  overflow-wrap: break-word;    /* Modern standard */
  word-wrap: break-word;        /* For older browsers */
  white-space: normal;          /* Allow wrapping */
}

/* Grid layout for multiple squares */
.captive-lead-details {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #ddd;
}

/* Dashboard Buttons */
.captive-dashboard-buttons {
  display: flex;
  gap: 10px;
}

.captive-dashboard-blue-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 16px;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.captive-dashboard-blue-button:hover {
  background-color: #0056b3;
}

/* Dashboard Controls */
.captive-dashboard-controls {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

#captive-dashboard-search {
  padding: 8px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  flex: 1;
}

.captive-dashboard-control-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 8px 12px;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
}

.captive-dashboard-control-button:hover {
  background-color: #0056b3;
}

/* Lead List */
.captive-lead-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.captive-lead-item {
  background-color: #fff;
  margin-bottom: 12px;
  padding: 12px;
  border-radius: 6px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.captive-lead-item:hover {
  background-color: #f0f4f8;
}

/* Lead Summary */
.captive-lead-summary {
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap; /* Allows items to wrap onto a new line */
}

.captive-lead-name,
.captive-lead-email {
  overflow-wrap: break-word; /* Modern property to break long words */
  word-wrap: break-word;     /* Fallback for older browsers */
  white-space: normal;       /* Allow wrapping */
}

.captive-toggle-icon {
  font-weight: bold;
  font-size: 1.2rem;
  width: 20px;
}

.captive-lead-name {
  font-weight: bold;
  color: #333;
}

/* RESPONSIVE FIX FOR EMAIL: allow it to shrink/wrap properly */
.captive-lead-email {
  flex: 1 1 0;
  min-width: 0;
  max-width: 100%;
  color: #666;
  font-size: 0.9rem;
}

/* Lead Details */
.captive-lead-details {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #ddd;
  font-size: 0.95rem;
  color: #444;
}

.captive-lead-details p {
  margin: 4px 0;
}

/* Lead Actions */
.captive-lead-actions {
  margin-top: 10px;
}

.captive-lead-actions button {
  background-color: #28a745;
  color: #fff;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.captive-lead-actions button:hover {
  background-color: #218838;
}

.captive-lead-actions button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Modal Overlay */
.captive-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Modal Content */
.captive-modal-content {
  background-color: #fff;
  padding: 20px;
  width: 90%;
  max-width: 500px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  position: relative;
}

/* Modal Header */
.captive-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.captive-modal-header h2 {
  margin: 0;
  font-size: 1.5rem;
  color: #333;
}

.captive-modal-close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

/* Modal Form Groups */
.captive-modal-form-group {
  margin-bottom: 15px;
}

.captive-modal-form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

.captive-modal-form-group select,
.captive-modal-form-group input[type="text"] {
  width: 100%;
  padding: 8px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Modal Actions */
.captive-modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.captive-modal-actions button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
}

.captive-confirm-button {
  background-color: #28a745;
  color: #fff;
}

.captive-confirm-button:hover {
  background-color: #218838;
}

.captive-cancel-button {
  background-color: #dc3545;
  color: #fff;
}

.captive-cancel-button:hover {
  background-color: #c82333;
}

/* Error Message */
#captive-error-message {
  color: #d9534f;
  font-weight: bold;
  text-align: center;
}

/* --- Responsive Media Queries --- */

/* Adjust header and buttons on smaller screens while keeping full-screen layout intact */
@media (max-width: 768px) {
  /* Dashboard Header: Stack items for smaller widths */
  .captive-dashboard-header {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  /* Reduce title size slightly */
  #captive-dashboard-title {
    font-size: 1.8rem;
    margin-bottom: 10px;
  }
  
  /* Dashboard Buttons: Allow wrapping and center alignment */
  .captive-dashboard-buttons {
    flex-wrap: wrap;
    justify-content: center;
  }
  
  /* Optionally adjust button padding/font-size on smaller screens */
  .captive-dashboard-blue-button {
    padding: 8px 12px;
    font-size: 0.9rem;
    margin-bottom: 10px;
  }
  
  /* Dashboard Controls: If you want to adjust the search and control buttons */
  .captive-dashboard-controls {
    flex-direction: column;
    align-items: center;
  }
  
  #captive-dashboard-search {
    width: 100%;
    margin-bottom: 10px;
  }
  
  .captive-dashboard-control-button {
    width: 100%;
    margin-bottom: 10px;
  }
}

@media (max-width: 480px) {
  #captive-dashboard-title {
    font-size: 1.5rem;
  }
  
  .captive-dashboard-blue-button,
  .captive-dashboard-control-button {
    font-size: 0.85rem;
  }
}
