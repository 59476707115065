.broker-login-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  max-width: 600px;
  margin: 0 auto;
  padding: 40px;
  background: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.broker-login-container h1 {
  font-size: 2.5em;
  margin-bottom: 10px;
  color: #333;
  text-align: center;
}

.broker-login-container h2 {
  font-size: 1.5em;
  margin-bottom: 20px;
  color: #555;
  text-align: center;
}

.broker-login-container form {
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
}

.broker-login-container input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1em;
  width: 80%;
  margin: 0 auto;
}

.broker-login-button {
  padding: 12px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1em;
  cursor: pointer;
  transition: background 0.3s ease;
  width: 40%;
  justify-self: center;
}

.broker-login-button:hover {
  background: #0056b3;
}


.broker-login-container .google-signin-btn img {
  width: 100%;
  max-width: 200px;
  cursor: pointer;
  
}

.broker-sign-up-section {
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #eee;
}

.broker-sign-up-section h2 {
  font-size: 1.8em;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
}

.broker-sign-up-section form {
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
}

.error-message, .success-message {
  font-size: 1em;
  text-align: center;
  margin-top: 10px;
}

.error-message {
  color: red;
}

.success-message {
  color: green;
}

  
  