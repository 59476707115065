
.lender-sign-up-header-section {
  width: 90%;
  margin: 0 auto;
}

.lender-sign-up-header-section h1 {
  text-align: center;
}

.lender-sign-up-li {
  font-size: 18px;
  line-height: 1.5;
}


.lender-sign-up-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 25px;
  padding-bottom: 25px;
}

.lender-sign-up-form {
  background: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

.lender-sign-up-form input,
.lender-sign-up-form button {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
}

.lender-sign-up-form input:focus {
  border-color: #007bff;
  outline: none;
}

.lender-sign-up-submit {
  background-color: #007bff;
}

.lender-sign-up-form button {
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.lender-sign-up-submit:hover {
  background-color: #0056b3;
}

.lender-sign-up-form p {
  text-align: center;
  margin-top: 20px;
}

.lender-sign-up-form p a {
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s;
}

.lender-sign-up-form p a:hover {
  color: #0056b3;
}

.password-requirements {
  font-size: 12px;
  color: #888;
  margin-top: -10px;
  margin-bottom: 10px;
}

.lender-sign-up-error-message {
  color: red;
  text-align: center;
  margin-top: 10px;
}

.lender-sign-up-success-message {
  color: green;
  text-align: center;
  margin-top: 10px;
}

.google-signin-btn {
  padding: 0;
  margin: 10px 0;
}

@media screen and (max-width: 600px) {

  .lender-sign-up-header-section h1 {
    font-size: 22px;
  }

  .lender-sign-up-header-section h2 {
    font-size: 20px;
  }

  .lender-sign-up-li {
    font-size: 16px;
  }

  .lender-sign-up-form {
    width: 70%;
  }
}