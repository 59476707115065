

.testimonials-body {
    display: grid;
    width: 60%;
    margin: 0 auto;
    padding-top: 2vw;
    padding-bottom: 5vw;
    grid-gap: 1vw;

}

.testimonials-body-testimonial {
    grid-template: 1fr 1fr 1fr / 1fr;
    border: 2px solid #ececec;
    padding-bottom: 1vw;
    border-radius: 8px;
    box-shadow: 0px 1.5px 1.5px 0px rgb(0 0 0 / 20%);
    padding: 10px 20px 10px 20px;

}

.testimonial-testimonial {
    grid-area: 1 / 1 / 1 / 1;
    font-style: italic;
    font-size: 1.2vw;
}

.testimonial-name {
    grid-area: 2 / 1 / 2 / 1;
    font-weight: bold;
}

.testimonial-customersince {
    grid-area: 3 / 1 / 3 / 1;
}


@media screen and (min-width: 992px) and (max-width: 1199px) {
    .testimonials-body {
        width: 80%;
    }

    .testimonial-testimonial {
        font-size: 1.5vw;
    }

    .testimonial-name {
        font-size: 1.5vw;
    }

    .testimonial-customersince {
        font-size: 1vw;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .testimonials-body {
        width: 80%;
        grid-gap: 2vw;
    }

    .testimonial-testimonial {
        font-size: 1.8vw;
    }

    .testimonial-name {
        font-size: 1.7vw;
    }

    .testimonial-customersince {
        font-size: 1.4vw;
    }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
    .testimonials-body {
        width: 90%;
        grid-gap: 2vw;
    }

    .testimonial-testimonial {
        font-size: 2.6vw;
    }

    .testimonial-name {
        font-size: 2.4vw;
    }

    .testimonial-customersince {
        font-size: 2vw;
    } 
}

@media screen and (min-width: 477px) and (max-width: 575px) {
    .testimonials-body {
        width: 90%;
        grid-gap: 2vw;
    }

    .testimonial-testimonial {
        font-size: 2.8vw;
    }

    .testimonial-name {
        font-size: 2.6vw;
    }

    .testimonial-customersince {
        font-size: 2.3vw;
    }    
}

@media screen and (min-width: 300px) and (max-width: 476px) {
    .testimonials-body {
        width: 90%;
        grid-gap: 2vw;
        padding-top: 4vw;
    }

    .testimonial-testimonial {
        font-size: 3.3vw;
    }

    .testimonial-name {
        font-size: 2.9vw;
    }

    .testimonial-customersince {
        font-size: 2.6vw;
    }    
}