

.HomePageTop {
    display: grid;
    grid-template: 1fr 1fr 1fr 1fr 1fr / 1fr 1 1fr 1fr 1fr 1fr 1fr;
    width: 80%;
    position: relative;
    margin: 0 auto;
    padding-top: 30px;
    
}

.homepagetop-border {
    grid-area: 6 / 1 / 7 / 7;
    position: relative;
    width: 100%;
    left: 0;
    border-bottom: 25px solid rgb(173, 137, 213);
    bottom: 50px;
}

.homepagetop-headers {
    grid-area: 1 / 1 / 3 / 4;
    display: flex;
    flex-direction: column;
    font-family: 'Montserrat', sans-serif;
    z-index: 1;
    text-align: center;
    align-self: center;

}

.homepagetop-7quotes {
    font-size: 36px;
    color: #7b0372;
    line-height: 1.5;
    
}

.homepagetop-one-local-agent {
    font-size: 36px;
    color: #efba33;
    line-height: 1.5;
}

.homepagetop-just-abc {
    font-size: 36px;
    color: #6a9a07;
}


.homepagetop-howitworks {
    color: #7b0372;
    font-style: italic;
    font-weight: bold;
    font-size: 30px;
    font-family: 'Montserrat', sans-serif;
    
}

.homepagetop-howitworks:hover {
    color: grey;
}


.homepagetop-video {
    grid-area: 3 / 1 / 4 / 7;
    width: 50%;
    margin: 0 auto;
    position: relative;
    bottom: 10%;
}

.homepagetop-video video {
    width: 100%;
    margin: 0 auto;
    cursor: pointer;
}

.play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    z-index: 1; 
}

.play-icon img {
    width: 70px;
}

.homepagetop-buttons {
    grid-area: 1 / 4 / 2 / 7;
    width: 100%;

}


#homeinsurancebutton {
    text-decoration: none;
    
}

#homequotebuttonlink {
    text-decoration: none;
}

#bundlequotebuttonlink {
    text-decoration: none;
}

#autoquotebuttonlink {
    text-decoration: none;
}

.homeinsurancebutton {
    transition: box-shadow 0.3s;
    font-size: 21px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    padding: 10px 40px 10px 30px;
    border: 1px solid #ececec;
    border-radius: 8px;
    color: #7b0372;
    background-color: #fff;
    width: 100%;
    text-decoration: none;
    cursor: pointer;
    margin: 20px 0px 20px 0px;
    box-shadow: 0px 1.5px 1.5px 0px rgb(0 0 0 / 20%);
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    
}

.homeinsurancebutton:hover {
    color: black;
    box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 20%);
}

.homeinsurancebutton img {
    width: 50px;
    position: relative;

    
}

.homeinsurancebutton p {
    position: relative;
}


.homeinsurancebutton span {
    font-size: 40px;
    position: relative;

}

#autoinsurancebutton {
    text-decoration: none;
}

.autoinsurancebutton {
    transition: box-shadow 0.3s;
    font-size: 21px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    padding: 10px 40px 10px 30px;
    border: 1px solid #ececec;
    border-radius: 8px;
    box-shadow: 0px 1.5px 1.5px 0px rgb(0 0 0 / 20%);
    color: #7b0372;
    background-color: #fff;
    width: 100%;
    cursor: pointer;
    margin: 20px 0px 20px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}

.autoinsurancebutton:hover {
    color: black;
    box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 20%);
}

.autoinsurancebutton img {
    width: 40px;
    position: relative;
    

}

.autoinsurancebutton span {
    font-size: 40px;
    position: relative;
    color: #efba33;

}

#bundlebutton {
    text-decoration: none;
}

.bundlebutton {
    transition: box-shadow 0.3s;
    font-size: 21px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    padding: 10px 40px 10px 30px;
    border: 1px solid #ececec;
    border-radius: 8px;
    box-shadow: 0px 1.5px 1.5px 0px rgb(0 0 0 / 20%);
    color: #7b0372;
    background-color: #fff;
    cursor: pointer;
    margin: 20px 0px 20px 0px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    width: 100%;
    z-index: 1;
}

.bundlebutton:hover {
    color: black;
    box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 20%);
}

.bundlebutton img {
    width: 40px;
    position: relative;
    float: left;
}

.bundlebutton p {
    word-wrap: break-word;
    padding: 0px 20px 0px 20px;
}

.bundlebutton span {
    font-size: 40px;
    position: relative;
    float: right;
    color: #6a9a07;
}


.homepage-top-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black background */
    z-index: 9999; /* ensure the modal is above other content */
  }
  
  .homepage-top-modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    width: 60%;
    height: auto;;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
  }

  @media screen and (min-width: 1200px) {
    .HomePageTop {
        width: 90%;
    }
    
    h1 {
        padding-right: 25px;
    }
  }

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .HomePageTop {
        width: 95%;
    }


}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .HomePageTop {
      width: 95%;
      margin: 0 auto;
    }

    .homepagetop-7quotes {
        font-size: 30px;
    }
    
    .homepagetop-one-local-agent {
        font-size: 30px;
    }
    
    .homepagetop-just-abc {
        font-size: 30px;
    }


    .homepagetop-howitworks {
        top: 90%;
        z-index: 1;
    }
    .homepagetop-video {
        width: 70%;
    }

    .homeinsurancebutton,
    .autoinsurancebutton,
    .bundlebutton {
        font-size: 18px;
    }

    .homepage-top-modal-content {
        width: 60%;
    }

}

@media screen and (min-width: 576px) and (max-width: 767px) {
    .HomePageTop {
        grid-template: 140px 90px 450px 250px 50px / 1fr;
        width: 95%;
        
    }

    .homepagetop-howitworks {
        grid-area: 2 / 1 / 2 / 1;
        margin: 0 auto;
        
    }

    .homepagetop-buttons {
        grid-area: 3 / 1 / 4 / 1;
        padding-top: 30px;
        width: 80%;
        margin: 0 auto;

    }

    .homepagetop-video {
        grid-area: 4 / 1 / 5 / 1;
        width: 70vw;
    }

    .homepagetop-border {
        margin-bottom: 30px;
        bottom: 50px;
    }

.homepage-top-modal-content {
    width: 50%;
}

}



@media screen and (min-width: 477px) and (max-width: 575px) {
    .HomePageTop {
        grid-template: 25vw 30vw 90vw 50vw 20px / 1fr;
        width: 95%;
        margin: 0 auto;
    }




    .homepagetop-howitworks {
        grid-area: 2 / 1 / 2 / 1;
        margin: 0 auto;
        
    }

    .homepagetop-buttons {
        grid-area: 3 / 1 / 4 / 1;
        width: 80vw;
        padding-top: 3vw;
        margin: 0 auto;
    }

    .homepagetop-video {
        grid-area: 4 / 1 / 5 / 1;
        width: 90%;
        display: inline-flex;
    }

    .play-icon {
        grid-area: 4 / 1 / 5 / 1;
        
    }

    .homepagetop-border {
        margin-bottom: 30px;
        bottom: 50px;
    }

.homepage-top-modal-content {
    width: 50%;
}

}


@media screen and (min-width: 300px) and (max-width: 476px) {
    .HomePageTop {
        grid-template: 50vw 30vw 130vw 50vw 25px / 1fr;
        width: 95%;
        margin: 0 auto;
    }


    .homepagetop-howitworks {
        grid-area: 2 / 1 / 2 / 1;
        margin: 0 auto;
        
    }

    .homepagetop-buttons {
        grid-area: 3 / 1 / 4 / 1;
        width: 96%;
        padding-top: 3vw;
        margin: 0 auto;
    }

    .homepagetop-video {
        grid-area: 4 / 1 / 5 / 1;
        width: 90%;
        display: inline-flex;
    }

    .play-icon {
        grid-area: 4 / 1 / 5 / 1;
        
    }

    .homepagetop-border {
        margin-bottom: 30px;
        bottom: 50px;
    }

.homepage-top-modal-content {
    width: 50%;
}

}
