.Community-Top {
    display: flex;
    background-color: rgb(240, 237, 243);
}

.Community-Top h1 {
    text-align: center;
    font-size: 54px;
    font-style: 'AlbertSans', sans-serif;
}

#community-top-img {
    display: flex;
    width: 100%;
    position: relative;
    padding: 0;

}

@media screen and (min-width: 700px) and (max-width: 1000px) {
    .Community-Top {
        font-size: 28px;
    }
} 

@media screen and (min-width: 330px) and (max-width: 699px) {
    .Community-Top {
        font-size: 18px;
    }
}