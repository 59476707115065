.dec-page-help-header {
    width: 90%;
    text-align: center;
    margin: 0 auto;
    padding-top: 25px;
    padding-bottom: 25px;
}

#dec-page-help-p {
    font-size: 24px;
}

.dec-page-help-grid-container {
    display: grid;
    grid-template-rows: auto;
    gap: 20px;
    width: 60%;
    margin: 0 auto;
}

.dec-page-help-grid-item {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 14px;
    background-color: #f9f9f9;
    display: block;  /* Ensure the link behaves as a block element */
    text-decoration: none;  /* Remove underline from links */
    color: inherit;  /* Inherit text color */
}

.dec-page-help-grid-item:hover {
    background-color: #f0f0f0;  /* Change background color on hover */
}

.dec-page-help-grid-item h2 {
    margin: 0;
}

.dec-page-help-grid-item .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
