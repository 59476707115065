/* Full-screen, semi-transparent background overlay */
.sell-lead-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); /* translucent grey overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* ensure it's on top */
  }
  
  /* Centered white box for the modal content */
  .sell-lead-modal-content {
    background-color: #fff;
    padding: 1.5rem;
    border-radius: 6px;
    max-width: 600px;
    width: 90%;
    box-shadow: 0 2px 10px rgba(0,0,0,0.3);
    font-family: Arial, sans-serif;
  }
  
  .sell-lead-modal-content h2 {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 1.25rem;
    color: #333;
  }
  
  .sell-lead-modal-content p {
    margin-bottom: 1rem;
    color: #555;
  }
  
  /* Remove default bullet points and spacing from list */
  .sell-lead-modal-content ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  /* Add a light border & spacing around each agent row */
  .sell-lead-modal-content li {
    margin-bottom: 0.75rem;
    border-bottom: 1px solid #eee;
    padding-bottom: 0.75rem;
  }
  
  /* Put text on the left, radio on the right */
  .sell-lead-modal-content li label {
    display: flex;
    align-items: center;
    justify-content: flex-start; /* no space-between */
    gap: 1rem; /* space between text and radio button */
    cursor: pointer;
  }
  
  /* The text block for the agent info */
  .sell-lead-modal-content li .agent-info {
    line-height: 1.4;
    color: #333;
  }
  
  /* Slightly enlarge the radio button */
  .sell-lead-modal-content li input[type='radio'] {
    transform: scale(1.2);
    cursor: pointer;
  }
  
  /* Button container at the bottom of the modal */
  .sell-lead-modal-content .modal-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 1.25rem;
  }
  
  /* Primary button style (blue) */
  .sell-lead-modal-content .modal-buttons button {
    margin-left: 0.5rem;
    padding: 0.6rem 1.2rem;
    border: none;
    background-color: #007bff; /* typical Bootstrap primary color */
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
    color: #fff;
    transition: background-color 0.2s;
  }
  
  .sell-lead-modal-content .modal-buttons button:hover {
    background-color: #0069d9; /* slightly darker shade */
  }
  
  .sell-lead-modal-content .modal-buttons button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  