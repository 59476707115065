
.faq-section {
    padding-bottom: 5vw;
    padding-top: 5vw;
}





.faq-accordion {
    box-sizing: border-box;
    border: 3px solid black;
    border-style: none;
    width: 80%;
    margin: 0 auto;
    position: relative;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    overflow: hidden;
    font-family: 'Albert Sans', sans-serif;
    background: white;
    font: black;
    top: .5vw;
    padding-bottom: 15px;
    }



  .faq-accordion__label,
  .faq-accordion__content {
    padding: 14px 20px;
    
  }
  
  .faq-accordion__label {
    display: block;
    color: black;
    font-weight: 550;
    font-size: 2vw;
    cursor: pointer;
    position: relative;
    transition: background 0.1s;
    z-index: 1;
    
    }
  
  .faq-accordion__label:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  
  .faq-accordion__label::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    width: 12px;
    height: 6px;
    background-image: url('data:image/svg+xml;utf8,<svg width="100" height="50" xmlns="http://www.w3.org/2000/svg"><polygon points="0,0 100,0 50,50" style="fill:gray;" /></svg>');
    background-size: contain;
    transition: transform 0.4s;
  }
  
  .faq-accordion__content {
    background: rgba(123, 3, 114, 0.2);
    line-height: 1.6;
    font-size: 1.8vw;
    display: none;
    border-radius: 4px;
    width: 90%;
    margin: 0 auto;
  
  }
  
  .faq-accordion__input {
    display: none;
    
  }
  
  .faq-accordion__input:checked ~ .faq-accordion__content {
    display: block;
  }
  
  .faq-accordion__input:checked ~ .faq-accordion__label::after {
    transform: translateY(-50%) rotate(0.5turn);
    
  }

  
  @media screen and (min-width: 992px) and (max-width: 1199px) {

    .accordion {
      width: 80%;
    }

  }


  @media screen and (min-width: 768px) and (max-width: 991px) {

    .accordion {
        width: 90%;
      }
  

      .faq-accordion__label {
        font-size: 2.5vw;
      }

      .faq-accordion__content {
        font-size: 2.3vw;
      }

  }

  @media screen and (min-width: 576px) and (max-width: 767px) {

    .faq-accordion {
        width: 90%;
      }

    
      .faq-accordion__label {
        font-size: 3vw;
      }

      .faq-accordion__content {
        font-size: 2.8vw;
      }
  }

  @media screen and (min-width: 477px) and (max-width: 575px) {

    .faq-accordion {
        width: 90%;
      }

      .faq-accordion__label {
        font-size: 3vw;
      }

      .faq-accordion__content {
        font-size: 3vw;
      }

  }

  @media screen and (min-width: 300px) and (max-width: 476px) {

    .faq-accordion {
        width: 90%;
      }
      
      .faq-accordion__label {
        font-size: 3.6vw;
      }

      .faq-accordion__content {
        font-size: 3.5vw;
      }

  }