


.homequotepage1-section {
    background-color: #f2fcfc;
    padding-top: 2.5vw;
    padding-bottom: 2.5vw;
    

}

.homequotepage1-header {
    display: flex;
    width: 80%;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    
}

.homequotepage1-section img {
    width: 6vw;
    padding-right: 1vw;
}

.quotepagebutton-div {
    display: flex;
    justify-content: center;
    padding-bottom: 2vw;
}

.quotepagebutton {
    display: inline-flex;
    position: relative;
    bottom: 6.9vw;
    border-radius: 40px;
    border: none;
    font-size: 1.5vw;
    padding: 15px 24px;
    background-color: #7B0072;
    color: white;
    font-weight: bold;
    cursor: pointer;
    margin: .5vw;
}

.quotepagebutton:hover {
    background-color: #af75ab;
}


.save-time-money-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .save-time-money-modal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 600px;
    text-align: center;
    max-height: 90%; /* Set maximum height */
    overflow-y: auto; /* Enable vertical scrolling */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .save-time-money-close-button {
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    position: relative;
    float: right;
    z-index: 9999;
  }
  
  .modal-buttons {
    margin-top: 20px;
  }

  #all-dont-uploading,
  #no-thanks-submit {
    position: relative;
    bottom: 20px;
  }
  
  .modal-buttons button {
    background: #007bff;
    border: none;
    color: white;
    padding: 10px 20px;
    margin: 5px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .modal-buttons button:hover {
    background: #0056b3;
  }


  .drag-drop-box-header {
    padding-bottom: 15px;
}

.save-time-money-title {
    margin: 0 auto;
    font-size: 18px;
    text-align: center;
}

.save-time-money-upload-paragraph {
    font-size: 18px;
    line-height: 1.25;
}

.save-time-money-find-help {
    font-size: 16px;
}


.save-time-money-details {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .save-time-money-details summary {
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .save-time-money-details[open] summary {
    margin-bottom: 10px;
  }
  
  .save-time-money-ordered-list {
    margin-top: 10px;
    font-size: 16px;
    line-height: 1.5;
    font-family: 'Montserrat', sans-serif;
    text-align: left;
  }

.contact-save-more-money {
    grid-area: 6 / 1 / 7 / 4;
    display: inline-flex;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    margin-bottom: 1vw;
    font-size: 1.5vw;
    flex-direction: column;
}


.drag-drop-box {
    grid-area: 7 / 1 / 8 / 4;
    padding: 20px;
    text-align: center;
    position: relative;
    width: 85%;
    margin: 0 auto;
  }
  
  .drag-drop-box.drag-over {
    border-color: #667;
  }

  .file-upload-container {
    width: 90%;
    height: auto; /* Adjust height as needed */
    border: 2px dashed #ccc;
    padding: 20px;
    text-align: center;
    margin: 0 auto;
    bottom: 35px;
    position: relative;
  }

  .file-upload-container.drag-over {
    border: 2px dashed black; 
    background-color: lightgrey; 
  }

  #drag-drop-box-sign-in,
  #uploadButtonLogout {
    position: relative;
    bottom: 40px;
    
  }

  /* loading spinner for when info is submitted. */

  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-top-color: #000;
    animation: spin 1s ease-in-out infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  


@media screen and (min-width: 992px) and (max-width: 1199px) {
    .homequotepage1-section img {
        width: 6vw;
        height: 6vw;
        padding-right: 1vw;
    }
    
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .homequotepage1-header h1 {
        font-size: 3vw;
        text-align: center;
        
    }
    .homequotepage1-section img {
        padding-right: 0vw;
    }

    .quotepagebutton {
        bottom: 8vw;
        font-size: 3vw;
        padding: 6px 12px;

    }

    .drag-drop-box-title {
        font-size: 1.3rem;
    }

}

@media screen and (min-width: 576px) and (max-width: 767px) {
    .homequotepage1-header h1 {
        font-size: 3.8vw;
        text-align: center;
        
    }
    .homequotepage1-section img {
        padding-right: 2vw;
    }    

    .quotepagebutton {
        font-size: 3vw;
        bottom: 13vw;
    }

    .drag-drop-box-title {
        font-size: 1.3rem;
    }

}

@media screen and (min-width: 477px) and (max-width: 575px) {
    .homequotepage1-header h1 {
        font-size: 5.5vw;
        text-align: center;
        
    }
    .homequotepage1-section img {
        width: 8vw;
        padding-right: 2vw;
    }    

    .quotepagebutton {
        font-size: 18px;
        padding: 4px 8px;
        bottom: 13vw;
    }

    .save-time-money-find-help {
        padding-bottom: 20px;
    }

    .drag-drop-box-title {
        font-size: 1.3rem;
    }

}

@media screen and (min-width: 300px) and (max-width: 476px) {
    .homequotepage1-header h1 {
        font-size: 5.5vw;
        text-align: center;
        
    }
    .homequotepage1-section img {
        width: 8vw;
        padding-right: 2vw;
    }    

    .quotepagebutton {
        font-size: 5vw;
        padding: 4px 8px;
        bottom: 14vw;
    }

    .save-time-money-find-help {
        padding-bottom: 35px;
    }

    .drag-drop-box-title {
        font-size: 1.1rem;
    }

    .file-upload-container {
        width: 99%;
        justify-self: center; /* This is for grid layout */
        margin: 0 auto; /* Ensure this is included */
        position: relative;
        right: 8%;
    }


}