.aboutus-ourstory {
    display: grid;
    grid-template: 40% 60% / 100%;
    padding-bottom: 5vw;
}

#aboutus-ourstory-head {
    display: grid;
    grid-area: 1 / 1 / 1 / 1;
    justify-content: center;
    font-size: 3.2vw;
    font-weight: 600;
    align-items: center;
}

#aboutus-ourstory-body {
    display: inline;
    grid-area: 2 / 1 / 2 / 1;
    margin: 0 auto;
    font-size: 1.8vw;
    width: 90%;
    line-height: 1.5;
}

#aboutus-ourstory-A {
    color: #7b0072;
    font-weight: 900;
    
}

#aboutus-ourstory-B {
    color: #efbb37;
    font-weight: 900;
}

#aboutus-ourstory-C {
    color: #6c9c11;
    font-weight: 900;

}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    #aboutus-ourstory-body {
        font-size: 2vw;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    #aboutus-ourstory-body {
        font-size: 2.3vw;
    }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
    #aboutus-ourstory-head {
        font-size: 4vw;
    }
    #aboutus-ourstory-body {
        position: relative;
        font-size: 2.8vw;
        bottom: 4vw;
    }
}

@media screen and (min-width: 477px) and (max-width: 575px) {
    .aboutus-ourstory {
        position: relative;
        bottom: 5vw;
    }
    
    #aboutus-ourstory-head {
        font-size: 4.5vw;
    }
    #aboutus-ourstory-body {
        position: relative;
        font-size: 4vw;
        bottom: 8vw;
        text-align: center;
    } 
}

@media screen and (min-width: 300px) and (max-width: 476px) {
    .aboutus-ourstory {
        position: relative;
        bottom: 8vw;
    }
    
    #aboutus-ourstory-head {
        font-size: 5.2vw;
    }
    #aboutus-ourstory-body {
        position: relative;
        font-size: 4.5vw;
        bottom: 10vw;
        text-align: center;
    } 
}