/* Toggle Button */
.archive-toggle-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 8px 12px;
    font-size: 0.9rem;
    cursor: pointer;
    margin-bottom: 16px;
  }
  
  .archive-toggle-button:hover {
    background-color: #0056b3;
  }
  
  /* General Styling for Quote Archive List */
  .broker-lender-quote-archive-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  /* Individual Quote Item Styling */
  .broker-lender-archived-quote-item {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 16px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    font-family: Arial, sans-serif;
  }
  
  /* Quote Details Section */
  .broker-lender-archived-quote-details {
    padding-bottom: 16px;
    border-bottom: 1px solid #ccc;
  }
  
  .broker-lender-archived-quote-details p {
    font-size: 0.9rem;
    color: #333;
    margin: 6px 0;
  }
  
  .broker-lender-archived-quote-details strong {
    color: #555;
    font-weight: 600;
  }
  
  /* Quote Status Section */
  .broker-lender-archived-quote-status {
    padding-top: 16px;
  }
  
  .broker-lender-archived-quote-status p {
    font-size: 0.9rem;
    color: #444;
    margin: 6px 0;
  }
  
  .broker-lender-archived-quote-status strong {
    color: #333;
    font-weight: 600;
  }
  
  /* Attached Files Styling */
  .broker-lender-archived-quote-details a {
    color: #007bff;
    text-decoration: none;
  }
  
  .broker-lender-archived-quote-details a:hover {
    text-decoration: underline;
  }
  
  /* Responsive Styles */
  @media (max-width: 600px) {
    .broker-lender-archived-quote-item {
      padding: 16px;
    }
    .broker-lender-archived-quote-details p,
    .broker-lender-archived-quote-status p {
      font-size: 0.85rem;
    }
  }
  
  