* {
    font-family: 'Albert Sans', sans-serif;
}

.homequoteinfo-Birthdate {
    display: grid;
    grid-template: 1fr 1fr 1fr / 1fr 1fr 1fr;
    border: 1px solid rgb(165, 165, 165);
    box-shadow: 0px 1px 1px 0px;
    border-radius: 4px;
    width: 60%;
    margin: 0 auto;
    justify-content: center;
    background-color: white;
    height: 28vw;
    margin-top: 2.5vw;
    padding-bottom: 2.8vw;

}

.birthdateinputs {
    display: flex;
    grid-area: 2 / 1 / 3 / 4;
    gap: 2vw;
    position: relative;
    justify-content: center;
    height: 3.5vw;
    position: relative;
    top: 5vw;
       
}

.birthdateinputs input {
    width: 25%;
    font-size: 18px;
}

.homequoteinfo-birthdate-abc {
    display: flex;
    flex-direction: row;
    grid-area: 1 / 1 / 2 / 4;
    justify-content: center;
    
}

.birthdate-abc-a,
.birthdate-abc-b,
.birthdate-abc-c {
    display: flex;
    flex-direction: column;
    text-align: center;
    min-width: 15vw;
    padding-top: 1vw;
    position: relative;
    top: 2vw;
}

#birthdate-abc-a, 
#birthdate-abc-b,
#birthdate-abc-c {
    display: flex;
    margin: 0 auto;
    border-radius: 50%;
    height: 6vw;
    width: 6vw;
    justify-content: center;
    align-items: center;
    font-size: 1.8vw;
      
}

#birthdate-abc-b {
    background-color: #7b0072;
    font-weight: bold;
    color: white;
}

#birthdate-abc-birthdate {
    font-weight: bold;
    font-size: 1.8vw;
}

#birthdate-abc-address,
#birthdate-abc-contact {
    font-size: 1.8vw;
}

#birthdate-abc-a,
#birthdate-abc-c {
    background-color: #e0c3de;
    color: white;

}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .homequoteinfo-Birthdate {
        width: 80%;
        height: 34vw;
    }
}



@media screen and (min-width: 576px) and (max-width: 767px) {
    .homequoteinfo-Birthdate {
        width: 80%;
        grid-template: 1fr 1fr 1fr 1fr 1fr / 1fr;
        height: 58vw;
    }

    .homequoteinfo-birthdate-abc {
        grid-area: 1 / 1 / 2 / 2;
    }

    .birthdate-abc-a, 
    .birthdate-abc-b,
    .birthdate-abc-c {
        width: 23vw;
    }

    #birthdate-abc-birthdate {
        font-size: 2.8vw;
    }
    
    #birthdate-abc-address,
    #birthdate-abc-contact {
        font-size: 2.8vw;
    }

    #birthdate-abc-a, 
    #birthdate-abc-b,
    #birthdate-abc-c {
        width: 10vw;
        height: 10vw;
        font-size: 5vw;
}

    .birthdateinputs {
        display: flex;
        flex-direction: column;
        grid-area: 2 / 1 / 3 / 2;
        justify-self: center;
        padding-top: 8vw;
        
    }

    .birthdateinputs input {
        width: 60vw;
    }
}

@media screen and (min-width: 477px) and (max-width: 575px) {
    .homequoteinfo-Birthdate {
        width: 80%;
        grid-template: 1fr 1fr 1fr 1fr 1fr / 1fr;
        height: 64vw;
    }

    .homequoteinfo-birthdate-abc {
        grid-area: 1 / 1 / 2 / 2;
    }

    .birthdate-abc-a, 
    .birthdate-abc-b,
    .birthdate-abc-c {
        width: 23vw;
    }

    #birthdate-abc-birthdate {
        font-size: 2.8vw;
    }
    
    #birthdate-abc-address,
    #birthdate-abc-contact {
        font-size: 2.8vw;
    }

    #birthdate-abc-a, 
    #birthdate-abc-b,
    #birthdate-abc-c {
        width: 10vw;
        height: 10vw;
        font-size: 5vw;
}

    .birthdateinputs {
        display: flex;
        flex-direction: column;
        grid-area: 2 / 1 / 3 / 2;
        justify-self: center;
        padding-top: 8vw;
        
    }

    .birthdateinputs input {
        width: 60vw;
    }
}

@media screen and (min-width: 300px) and (max-width: 476px) {
    .homequoteinfo-Birthdate {
        width: 80%;
        grid-template: 1fr 1fr 1fr 1fr 10% / 1fr;
        height: auto;
    }

    .homequoteinfo-birthdate-abc {
        grid-area: 1 / 1 / 2 / 2;
    }

    .birthdate-abc-a, 
    .birthdate-abc-b,
    .birthdate-abc-c {
        width: 23vw;
    }

    #birthdate-abc-address {
        font-size: 2.8vw;
    }
    
    #birthdate-abc-birthdate,
    #birthdate-abc-contact {
        font-size: 2.8vw;
    }

    #birthdate-abc-a, 
    #birthdate-abc-b,
    #birthdate-abc-c {
        width: 10vw;
        height: 10vw;
        font-size: 5vw;
}

    .birthdateinputs {
        display: flex;
        flex-direction: column;
        grid-area: 2 / 1 / 3 / 2;
        justify-self: center;
        padding-top: 13vw;
        
    }

    .birthdateinputs input {
        width: 60vw;
    }
}

